import { Component, Vue } from "vue-facing-decorator";
import VsInput from "@/components/vs-input/vs-input.vue";
import { Suggestion } from "@/models/dataset";

@Component({
    name: "HomeViewComponent",
    components: {
        VsInput,
    },
})
export default class HomeViewComponent extends Vue {
    public display = "";

    /* This method is called when a search suggestion is selected. It takes a parameter suggestion which can be either a Suggestion object or a string. 
    If it's a string, the method extracts the term and navigates to the "Search" route with the term as a parameter. If it's a Suggestion object, it extracts 
    the value and type from the suggestion and navigates to the "Search" route with both parameters.*/
    onSearch(suggestion: Suggestion | string): void {
        let term;
        if (typeof suggestion === "string") {
            term = suggestion;
            this.$router.push({ name: "Search", params: { display: term } });
        } else if (suggestion instanceof Suggestion) {
            term = suggestion.value;
            this.$router.push({ name: "Search", params: { display: term, type: suggestion.type } });
        }
    }

    /* This method is called when the user initiates a search. It navigates to the "Search" route with the display property as a parameter. */
    // search(): void {
    //     this.$router.push({ name: "Search", params: { display: this.display } });
    // }
}
