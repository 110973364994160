import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-full h-full text-xs 3xl:text-sm lg:pt-3 3xl:pt-5 text-tethys-5" }
const _hoisted_2 = { class: "w-full h-full flex gap-2" }
const _hoisted_3 = { class: "w-full h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-2" }
const _hoisted_4 = { class: "relative w-full" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "absolute top-0 right-2 transform translate-x-2 -translate-y-2 bg-primary-tethys text-white text-xs font-bold w-4 h-4 flex items-center justify-center rounded-full" }
const _hoisted_9 = {
  key: 0,
  class: "absolute top-full left-0 mt-1 bg-white border border-gray-300 shadow-lg rounded-md w-56 z-50 allFilters-dropdown"
}
const _hoisted_10 = { class: "p-2 flex flex-wrap items-center gap-1 border-b border-gray-300" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick", "disabled"]
const _hoisted_13 = { class: "uppercase pr-2" }
const _hoisted_14 = { class: "flex items-center gap-1" }
const _hoisted_15 = {
  key: 0,
  class: "bg-primary-tethys text-white text-xs font-bold w-4 h-4 flex items-center justify-center rounded-full"
}
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  key: 2,
  class: "fas fa-chevron-down text-gray-300"
}
const _hoisted_18 = {
  key: 0,
  class: "absolute top-full left-0 mt-1 bg-white border border-gray-300 shadow-lg rounded-md w-56 z-50 facet-dropdown"
}
const _hoisted_19 = { class: "p-2 flex flex-wrap items-center gap-1 border-b border-gray-300" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "relative w-full" }
const _hoisted_22 = ["onUpdate:modelValue", "placeholder", "disabled"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "p-2 max-h-40 overflow-y-auto" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = ["checked"]
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { class: "shrink-0 w-fit" }
const _hoisted_30 = ["title"]
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { key: 1 }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = {
  key: 0,
  class: "absolute mt-1 bg-white border border-gray-300 shadow-lg rounded-md w-32 z-50 sort-dropdown"
}
const _hoisted_36 = { class: "p-2" }
const _hoisted_37 = ["title"]
const _hoisted_38 = ["title"]
const _hoisted_39 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleDropdown('allFilters'))),
            disabled: _ctx.selectedTags.length < 1,
            class: _normalizeClass([
                            'w-full min-w-[8rem] flex items-center bg-white border border-gray-300 px-3 py-2 rounded-md font-semibold shadow-sm hover:bg-gray-100 transition allFilters-dropdown', 
                            { 'text-gray-400 hover:bg-white': _ctx.selectedTags.length < 1 }])
          }, [
            _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fa-solid fa-sliders pr-2" }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("all_filters")) + " ", 1),
            (_ctx.selectedTags.length > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                  _createElementVNode("span", {
                    class: "italic underline pl-3",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.resetFilters())),
                    title: _ctx.$t('reset_tooltip')
                  }, "Reset", 8, _hoisted_7),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.selectedTags.length), 1)
                ]))
              : _createCommentVNode("", true)
          ], 10, _hoisted_5),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (_ctx.openDropdown === 'allFilters')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      (_ctx.selectedTags.length > 0)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.selectedTags, (selected, i) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: i,
                              class: "bg-gray-200 px-2 py-1 rounded flex items-center gap-1 cursor-pointer"
                            }, [
                              _createTextVNode(_toDisplayString(selected.val) + " ", 1),
                              _createElementVNode("button", {
                                onClick: _withModifiers(($event: any) => (_ctx.removeSelection(selected.category, selected)), ["stop"]),
                                class: "bg-gray-50 text-gray-500 hover:text-gray-700 w-3 h-3 pb-0.5 flex items-center justify-center rounded-full"
                              }, " × ", 8, _hoisted_11)
                            ]))
                          }), 128))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fixedFacets, (facetKey) => {
          return (_openBlock(), _createElementBlock("div", {
            key: facetKey,
            class: "relative w-full facet-dropdown"
          }, [
            _createElementVNode("button", {
              onClick: ($event: any) => (_ctx.toggleDropdown(facetKey)),
              class: _normalizeClass([
                            'w-full min-w-[8rem] flex items-center justify-between relative bg-white border border-gray-300 pl-3 pr-2 py-2 rounded-md font-semibold shadow-sm hover:bg-gray-100 transition', 
                        { '!bg-tethys-3 border-blue-200': _ctx.facets[facetKey] && _ctx.facets[facetKey].some(item => item.active) }]),
              disabled: !_ctx.facets[facetKey]
            }, [
              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("facet_" + facetKey)), 1),
              _createElementVNode("div", _hoisted_14, [
                (_ctx.facets[facetKey] && _ctx.facets[facetKey].some(item => item.active))
                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.facets[facetKey].filter(item => item.active).length), 1))
                  : _createCommentVNode("", true),
                (_ctx.facets[facetKey] && _ctx.facets[facetKey].some(item => item.active))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      onClick: _withModifiers(($event: any) => (_ctx.clearFacet(facetKey)), ["stop"]),
                      class: "text-gray-500 hover:text-gray-700 focus:outline-none"
                    }, _cache[7] || (_cache[7] = [
                      _createElementVNode("i", { class: "fas fa-times text-primary-tethys" }, null, -1)
                    ]), 8, _hoisted_16))
                  : (_openBlock(), _createElementBlock("i", _hoisted_17))
              ])
            ], 10, _hoisted_12),
            _createVNode(_Transition, { name: "fade" }, {
              default: _withCtx(() => [
                (_ctx.openDropdown === facetKey)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facets[facetKey]?.filter(item => item.active), (selected, i) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: i,
                            class: "bg-gray-200 text-xs pl-2 pr-1 py-1 rounded flex items-center gap-1.5 cursor-pointer"
                          }, [
                            _createTextVNode(_toDisplayString(selected.val) + " ", 1),
                            _createElementVNode("button", {
                              onClick: _withModifiers(($event: any) => (_ctx.removeSelection(facetKey, selected)), ["stop"]),
                              class: "bg-gray-50 text-gray-500 hover:text-gray-700 w-3 h-3 pb-0.5 flex items-center justify-center rounded-full"
                            }, " × ", 8, _hoisted_20)
                          ]))
                        }), 128)),
                        _createElementVNode("div", _hoisted_21, [
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": ($event: any) => ((_ctx.searchQueries[facetKey]) = $event),
                            type: "text",
                            placeholder: _ctx.$t('search'),
                            class: "w-full mt-1 text-xs px-2 py-1 border border-gray-200 outline-none",
                            disabled: !_ctx.facets[facetKey]
                          }, null, 8, _hoisted_22), [
                            [_vModelText, _ctx.searchQueries[facetKey]]
                          ]),
                          (_ctx.searchQueries[facetKey])
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                onClick: ($event: any) => (_ctx.clearSearch(facetKey)),
                                class: "absolute right-2 top-1/2 pt-1 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                              }, _cache[8] || (_cache[8] = [
                                _createElementVNode("i", { class: "fas fa-times" }, null, -1)
                              ]), 8, _hoisted_23))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("ul", _hoisted_24, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions(facetKey), (option, i) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: i,
                            class: "px-1 py-1 flex items-center gap-2 cursor-pointer hover:bg-gray-100",
                            onClick: ($event: any) => (_ctx.toggleSelection(facetKey, option))
                          }, [
                            _createElementVNode("input", {
                              type: "checkbox",
                              checked: option.active,
                              class: "cursor-pointer"
                            }, null, 8, _hoisted_26),
                            (facetKey === 'doctype' || facetKey === 'language')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_ctx.$t(option.val) + " (" + option.count + ")"), 1))
                              : (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(option.val + " (" + option.count + ")"), 1))
                          ], 8, _hoisted_25))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleDropdown('sort'))),
          class: "min-w-28 flex items-center gap-1.5 bg-white border border-gray-300 px-3 py-2 font-semibold shadow-sm hover:bg-gray-100 transition sort-dropdown",
          title: _ctx.$t('sort_by_tooltip')
        }, [
          (_ctx.sortByAttribute === '_score')
            ? (_openBlock(), _createElementBlock("span", _hoisted_31, _cache[9] || (_cache[9] = [
                _createElementVNode("span", { class: "pl-0.5" }, [
                  _createElementVNode("i", { class: "fa-solid fa-signal -rotate-90" })
                ], -1)
              ])))
            : (_openBlock(), _createElementBlock("span", _hoisted_32, [
                (_ctx.sortByOrder === 'desc')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_33, _cache[10] || (_cache[10] = [
                      _createElementVNode("i", { class: "fa-solid fa-down-long" }, null, -1)
                    ])))
                  : (_openBlock(), _createElementBlock("span", _hoisted_34, _cache[11] || (_cache[11] = [
                      _createElementVNode("i", { class: "fa-solid fa-up-long" }, null, -1)
                    ]))),
                _cache[12] || (_cache[12] = _createElementVNode("i", { class: "fa-solid fa-hourglass-half" }, null, -1))
              ])),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('sort_by')), 1)
        ], 8, _hoisted_30),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.openDropdown === 'sort')
              ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                  _createElementVNode("ul", _hoisted_36, [
                    (_ctx.hasSearchTerm)
                      ? (_openBlock(), _createElementBlock("li", {
                          key: 0,
                          class: "px-1 py-1 cursor-pointer hover:bg-gray-100",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeOrdering('relevance', ''))),
                          title: _ctx.$t('relevance_tooltip')
                        }, [
                          _cache[13] || (_cache[13] = _createElementVNode("span", { class: "pr-2" }, [
                            _createElementVNode("i", { class: "fa-solid fa-signal -rotate-90" })
                          ], -1)),
                          _createTextVNode(_toDisplayString(_ctx.$t('relevance')), 1)
                        ], 8, _hoisted_37))
                      : _createCommentVNode("", true),
                    _createElementVNode("li", {
                      class: "px-1 py-1 cursor-pointer hover:bg-gray-100",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeOrdering('date', 'descending'))),
                      title: _ctx.$t('sort_date_desc_tooltip')
                    }, [
                      _cache[14] || (_cache[14] = _createElementVNode("span", { class: "pr-1" }, [
                        _createElementVNode("i", { class: "fa-solid fa-down-long" }),
                        _createElementVNode("i", { class: "fa-solid fa-hourglass-half" })
                      ], -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('facet_year')), 1)
                    ], 8, _hoisted_38),
                    _createElementVNode("li", {
                      class: "px-1 py-1 cursor-pointer hover:bg-gray-100",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeOrdering('date', 'ascending'))),
                      title: _ctx.$t('sort_date_asc_tooltip')
                    }, [
                      _cache[15] || (_cache[15] = _createElementVNode("span", { class: "pr-1" }, [
                        _createElementVNode("i", { class: "fa-solid fa-up-long" }),
                        _createElementVNode("i", { class: "fa-solid fa-hourglass-half" })
                      ], -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('facet_year')), 1)
                    ], 8, _hoisted_39)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}