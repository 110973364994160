import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "flex flex-col items-center w-full h-full bg-tethys-1 overflow-hidden",
  autocomplete: "off"
}
const _hoisted_2 = { class: "search-banner w-full bg-cover bg-no-repeat min-h-64 lg:min-h-[350px] bg-[position:50%_50%] relative before:absolute before:top-0 before:left-0 before:w-full before:h-4 before:bg-gradient-to-b before:from-black before:to-transparent before:opacity-20 after:absolute after:bottom-0 after:left-0 after:w-full after:h-4 after:bg-gradient-to-t after:from-black after:to-transparent after:opacity-20" }
const _hoisted_3 = {
  id: "filters",
  class: "w-5/6 2xl:w-4/6 h-40 md:h-24 2xl:h-16 3xl:h-20 bg-tethys-1"
}
const _hoisted_4 = { class: "hidden sm:flex w-5/6 2xl:w-4/6" }
const _hoisted_5 = { class: "w-full bg-white h-11 rounded-md flex mb-4 pl-3 items-center justify-between" }
const _hoisted_6 = {
  key: 0,
  class: "px-2 py-1 text-xs bg-white rounded-md",
  role: "alert"
}
const _hoisted_7 = { class: "font-medium" }
const _hoisted_8 = { class: "font-semibold" }
const _hoisted_9 = { class: "sm:hidden w-5/6" }
const _hoisted_10 = { class: "bg-[#eff3f7] rounded-md p-2 my-4 flex items-center justify-between" }
const _hoisted_11 = {
  class: "px-2 py-1 text-xs bg-white rounded-md",
  role: "alert"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "font-semibold" }
const _hoisted_14 = { class: "bg-[#eff3f7] rounded-md p-2 my-4 flex items-center justify-between" }
const _hoisted_15 = { class: "flex flex-col md:flex-row w-10/12 xl:w-5/6 2xl:w-4/6" }
const _hoisted_16 = { class: "flex sm:hidden w-5/6" }
const _hoisted_17 = { class: "bg-[#eff3f7] h-11 rounded-md my-3 flex w-full items-center justify-between" }
const _hoisted_18 = ["title"]
const _hoisted_19 = { class: "hidden sm:flex w-5/6 2xl:w-4/6 pb-10" }
const _hoisted_20 = { class: "w-full h-11 rounded-md my-4 flex items-center justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vs_input = _resolveComponent("vs-input")!
  const _component_FacetsComponent = _resolveComponent("FacetsComponent")!
  const _component_PaginationTop = _resolveComponent("PaginationTop")!
  const _component_vs_result = _resolveComponent("vs-result")!
  const _component_PaginationBase = _resolveComponent("PaginationBase")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vs_input, {
        propDisplay: _ctx.searchTerm,
        placeholder: _ctx.$t('enter_your_search_term'),
        onSearchChange: _ctx.onSearch
      }, null, 8, ["propDisplay", "placeholder", "onSearchChange"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FacetsComponent, {
        facets: _ctx.facets,
        sortByAttribute: _ctx.sortByAttribute,
        sortByOrder: _ctx.sortByOrder,
        hasSearchTerm: _ctx.hasSearchTerm,
        onFilter: _ctx.onFilter,
        onClearFacet: _ctx.onClearFacetCategory,
        onChangeOrdering: _ctx.onChangeOrdering
      }, null, 8, ["facets", "sortByAttribute", "sortByOrder", "hasSearchTerm", "onFilter", "onClearFacet", "onChangeOrdering"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.hasSearchTerm)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('your_search_term')), 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.stringSearchTerm), 1),
              _createTextVNode(_toDisplayString(_ctx.$t('yielded')) + " ", 1),
              _createElementVNode("strong", null, _toDisplayString(_ctx.results.length > 0 ? _ctx.numFound : 0), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('results')) + ": ", 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_PaginationTop, {
          class: "pr-2 ml-auto",
          data: _ctx.pagination,
          onMenuClick: _ctx.onMenuClick
        }, null, 8, ["data", "onMenuClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          (_ctx.hasSearchTerm)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                _createTextVNode(_toDisplayString(_ctx.$t('your_search_term')) + " ", 1),
                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.stringSearchTerm), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('yielded') + " "), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("strong", null, _toDisplayString(_ctx.results.length > 0 ? _ctx.numFound : 0), 1),
          _cache[1] || (_cache[1] = _createTextVNode(" results "))
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_PaginationTop, {
          class: "pr-2 ml-auto",
          data: _ctx.pagination,
          onMenuClick: _ctx.onMenuClick
        }, null, 8, ["data", "onMenuClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", null, [
        _createVNode(_component_vs_result, { datasets: _ctx.results }, null, 8, ["datasets"])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("a", {
          class: "inline-block px-2 py-1 ml-2 mr-1 border border-gray-300 shadow-sm hover:bg-gray-300 transition-colors text-xs font-normal text-primary-tethys rounded cursor-pointer",
          title: _ctx.$t('go_to_search_bar')
        }, [
          _createElementVNode("i", {
            class: "fas fa-arrow-up",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollToTop()))
          })
        ], 8, _hoisted_18),
        _createVNode(_component_PaginationTop, {
          class: "pr-2 ml-auto",
          data: _ctx.pagination,
          onMenuClick: _ctx.onMenuClick
        }, null, 8, ["data", "onMenuClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_PaginationBase, {
          data: _ctx.pagination,
          onMenuClick: _ctx.onMenuClick
        }, null, 8, ["data", "onMenuClick"])
      ])
    ])
  ]))
}