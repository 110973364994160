<template>
    <!-- Parent container with multiple rows -->
    <div class="flex flex-col items-center">
       
        <!-- Search container with relative positioning -->
        <div class="w-5/6 2xl:w-4/6 pt-8 mx-auto relative">

            <!-- Search input wrapper -->
            <div class="relative flex items-center">
                <!-- Search box -->
                <div class="h-20 px-3 relative flex w-full items-center bg-gray-100 bg-opacity-75 shadow-md shadow-gray-500 border-gray-300 rounded-xl border-y border-l">
                    <!-- Search button -->
                    <button class="h-14 z-0 pl-5 pr-4 text-blue-300 rounded-s-lg bg-white" @click="search()" :title="$t('search')">
                        <i class="fas fa-search text-2xl"></i>
                    </button>
                    <!-- Separator -->
                    <span class="flex items-center h-14 bg-white">
                        <span class="h-3/4 border-r-2 border-gray-200 "></span>
                    </span>
                    <!-- Input field for search query -->
                    <input
                        id="search_query"
                        v-model="display"
                        class="h-14 w-full py-2.5 text-gray-700 placeholder-gray-400 border-none focus:ring-0 focus:outline-none rounded-e-xl"
                        type="text"
                        name="q"
                        autocomplete="off"
                        v-bind:placeholder="placeholder"
                        @input="searchChanged"
                        @keydown.down="onArrowDown"
                        @keydown.up="onArrowUp"
                        @keydown.enter="onEnter"
                        @keydown.tab="close"
                        @focus="focus"
                    />
                    <!-- Clear Button (appears only when input is not empty) -->
                    <button v-if="display" class="absolute right-6 px-4 py-2 border-x-2 border-y-2 border-gray-100 text-gray-400 hover:bg-gray-200 " @click="clearSearch()" :title="$t('clean_search_bar')">
                        <i class="fas fa-times"></i>
                    </button>

                <!-- </div> -->
                </div>

                <!-- Search button -->
                <!-- <button class="z-0 px-4 py-2.5 bg-secondary-tethys hover:bg-teal-500 text-white rounded-e-md border border-[#02609a] shadow-lg" @click="search()" :title="$t('search')">
                    <i class="fas fa-search"></i>
                </button> -->

            </div>

            <!-- Suggestions list -->
            <div class="absolute w-full z-50 border-x-8 border-transparent">
                <!-- <ul v-show="showResults" class="absolute left-3 right-3 bg-gray-200 bg-opacity-80 border border-gray-300 rounded-b-lg max-h-[198px] overflow-y-auto shadow-xl"> -->
                <ul v-show="showResults" class="bg-gray-200 bg-opacity-80 border border-gray-300 rounded-b-lg max-h-[198px] overflow-y-auto shadow-xl w-full">

                    <!-- Loading indicator -->
                    <li v-if="isLoading" class="p-2 text-gray-500">Loading results...</li>
                    <!-- Iterating over suggestions -->
                    <li
                        v-for="(result, i) in suggestions"
                        v-else
                        v-bind:key="i"
                        v-bind:ref="setItemRef"
                        class="py-1 px-3 cursor-pointer hover:bg-teal-100"
                        v-bind:class="{ 'bg-teal-200': isSelected(i) }"
                        @click.prevent="select(result)"
                    >
                        <!-- Displaying suggestion result -->
                        <div class="text-sm">
                            <label v-html="formatSuggestion(result)"></label>
                        </div>
                    </li>
                </ul>
            </div>


            <!-- Submit data Button -->
            <button v-if="!display" class="mt-3.5 bg-tethys-6 hover:bg-tethys-7 text-white font-bold py-2.5 px-6 rounded-lg shadow-lg">
                {{ $t('submit') }}
            </button>

        </div>

       
        <!-- hover:bg-teal-100 -->
    </div>

</template>

<script lang="ts">
import VsInput from "./vs-input";
export default VsInput;
</script>


<style lang="scss" scoped>
// .columns:not(:last-child) {
//     margin-bottom: calc(1.5rem - 0.75rem);
//     display: flex;
// }



.main-search-from-bg {
    background-color: #fff;
    padding: 0.2em;
}

.column {
    display: block;
    // flex-basis: 0;
    // flex-grow: 1;
    // flex-shrink: 1;
    /* padding: 0.75rem; */
    position: relative;
    padding: 0;
}

.button {
    background-color: #33cccc;
    // border-color: #dbdbdb;
    border-width: 0;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0;
    text-align: center;
    white-space: nowrap;
}

.search-box {
    background-image: url(./../../assets/site/img/white-pixel.png);
    background-repeat: repeat;
    padding: 1.8em;
    // width: 28em;
}

.control .input,
.control .input:focus,
.column .input.selected {
    // background-color: white;
    /* border-color: #dbdbdb; */
    // border: 0;
    // outline: none;
    border-radius: 4px;
    color: #363636;
}
input {
    border-width: 0;
    box-shadow: none;
}

.autocomplete-results {
    padding: 0;
    margin: 0;
    margin-left: 0em;
    border: 1px solid #eeeeee;
    list-style-type: none;
    z-index: 1000;
    position: absolute;
    max-height: 192px;
    overflow-y: auto;
    overflow: hidden;
    background: white;
    width: 100%;
    border: 1px solid #ccc;
    border-top: 0;
    color: black;
}

.autocomplete-result-item {
    list-style: none;
    text-align: left;
    padding: 0px 0px 0px 5px; // top,right,bottom,left
    cursor: pointer;
}

.autocomplete-result-item.is-active {
    background: rgba(0, 180, 255, 0.15);
    // background: #3cc;
}

.autocomplete-result-item:hover {
    // background: rgba(0, 180, 255, 0.075);
    background: #baedf1;
}
</style>
