import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"
import _imports_0 from '../../assets/site/img/ORCID-iD_icon_unauth_vector.svg'


const _hoisted_1 = {
  class: "flex flex-col items-center w-full h-full bg-tethys-1 overflow-hidden",
  autocomplete: "off"
}
const _hoisted_2 = { class: "search-banner w-full bg-cover bg-no-repeat min-h-64 lg:min-h-[350px] bg-[position:50%_50%] relative before:absolute before:top-0 before:left-0 before:w-full before:h-4 before:bg-gradient-to-b before:from-black before:to-transparent before:opacity-20 after:absolute after:bottom-0 after:left-0 after:w-full after:h-4 after:bg-gradient-to-t after:from-black after:to-transparent after:opacity-20" }
const _hoisted_3 = {
  key: 0,
  class: "py-5"
}
const _hoisted_4 = { class: "w-10/12 xl:w-5/6 2xl:w-4/6 mx-auto" }
const _hoisted_5 = { class: "flex flex-wrap" }
const _hoisted_6 = { class: "w-full lg:w-8/12" }
const _hoisted_7 = { class: "flex justify-between items-center" }
const _hoisted_8 = { class: "px-2 py-2 text-sm text-gray-400 uppercase" }
const _hoisted_9 = { class: "bg-white border shadow-xl rounded-xl" }
const _hoisted_10 = { class: "flex justify-end items-center pt-3 pb-2 pr-4" }
const _hoisted_11 = ["title"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["title"]
const _hoisted_14 = ["title"]
const _hoisted_15 = {
  key: 0,
  class: "flex flex-wrap px-5 pb-4"
}
const _hoisted_16 = { class: "w-full font-bold text-black text-xl" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { class: "inline-block px-5 pb-4" }
const _hoisted_22 = ["title"]
const _hoisted_23 = ["href"]
const _hoisted_24 = { class: "text-gray-500 px-5 pb-12" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { class: "flex flex-wrap gap-1 items-center" }
const _hoisted_27 = ["href"]
const _hoisted_28 = { class: "absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 hidden group-hover:block bg-black text-white text-xs rounded px-2 py-1 whitespace-nowrap" }
const _hoisted_29 = {
  key: 1,
  class: "pr-1"
}
const _hoisted_30 = { key: 1 }
const _hoisted_31 = {
  key: 1,
  class: "flex flex-wrap px-5 pb-10"
}
const _hoisted_32 = { class: "w-full" }
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = { key: 0 }
const _hoisted_36 = { key: 1 }
const _hoisted_37 = { class: "flex flex-wrap px-5 pb-5" }
const _hoisted_38 = {
  key: 0,
  class: "w-full"
}
const _hoisted_39 = { class: "relative" }
const _hoisted_40 = { class: "hidden md:table w-full mb-4 text-xs" }
const _hoisted_41 = { class: "text-left" }
const _hoisted_42 = { class: "border-b-2 border-gray-200 bg-gray-100" }
const _hoisted_43 = { class: "text-gray-500 px-4 py-3" }
const _hoisted_44 = { class: "text-gray-500 px-4 py-3" }
const _hoisted_45 = { class: "text-gray-500 px-4 py-3" }
const _hoisted_46 = { class: "text-gray-500 px-4 py-3" }
const _hoisted_47 = { class: "border-b-2 border-gray-200 px-4 py-3" }
const _hoisted_48 = { class: "border-b-2 border-gray-200 px-4 py-3" }
const _hoisted_49 = ["title"]
const _hoisted_50 = { class: "border-b-2 border-gray-200 px-4 py-3" }
const _hoisted_51 = { class: "border-b-2 border-gray-200 px-4 py-3" }
const _hoisted_52 = { key: 0 }
const _hoisted_53 = ["title", "href"]
const _hoisted_54 = { key: 0 }
const _hoisted_55 = ["title", "onClick"]
const _hoisted_56 = { key: 1 }
const _hoisted_57 = ["title"]
const _hoisted_58 = { class: "font-sans font-bold uppercase" }
const _hoisted_59 = { class: "truncate mb-1" }
const _hoisted_60 = ["href"]
const _hoisted_61 = { class: "mb-1" }
const _hoisted_62 = {
  key: 0,
  class: "absolute inset-0 bg-tethys-3 bg-opacity-80 flex items-center justify-center text-tethys-5 text-center text-xs md:text-lg p-4 rounded-lg"
}
const _hoisted_63 = { class: "font-semibold" }
const _hoisted_64 = { class: "font-bold" }
const _hoisted_65 = { class: "hidden md:inline" }
const _hoisted_66 = { class: "font-bold text-base" }
const _hoisted_67 = {
  key: 0,
  class: "font-bold text-xs pl-2 text-tethys-4"
}
const _hoisted_68 = { key: 0 }
const _hoisted_69 = ["src"]
const _hoisted_70 = { key: 1 }
const _hoisted_71 = { class: "overflow-auto max-h-[500px] sm:max-w-3xl lg:max-w-5xl border border-gray-300" }
const _hoisted_72 = { class: "table-auto w-full text-left border-collapse" }
const _hoisted_73 = { class: "bg-gray-100 sticky top-0 z-10" }
const _hoisted_74 = {
  key: 0,
  class: "text-gray-600 py-2"
}
const _hoisted_75 = {
  key: 0,
  class: "text-center text-gray-500 py-4"
}
const _hoisted_76 = { key: 2 }
const _hoisted_77 = ["src"]
const _hoisted_78 = { key: 3 }
const _hoisted_79 = { class: "text-gray-500" }
const _hoisted_80 = {
  key: 0,
  class: "font-bold text-base"
}
const _hoisted_81 = { key: 0 }
const _hoisted_82 = {
  key: 0,
  class: "flex flex-wrap mb-8 mt-3"
}
const _hoisted_83 = { class: "w-full font-bold uppercase mb-4" }
const _hoisted_84 = {
  key: 0,
  class: "w-full justify-normal"
}
const _hoisted_85 = { key: 0 }
const _hoisted_86 = { key: 1 }
const _hoisted_87 = { key: 0 }
const _hoisted_88 = { key: 1 }
const _hoisted_89 = { key: 1 }
const _hoisted_90 = {
  key: 1,
  class: "flex flex-wrap mb-8"
}
const _hoisted_91 = { class: "w-full font-bold uppercase mb-4" }
const _hoisted_92 = {
  key: 0,
  class: "w-full"
}
const _hoisted_93 = { key: 0 }
const _hoisted_94 = { key: 1 }
const _hoisted_95 = { key: 0 }
const _hoisted_96 = { key: 1 }
const _hoisted_97 = { key: 1 }
const _hoisted_98 = { class: "flex flex-wrap mb-8" }
const _hoisted_99 = { class: "w-full font-bold uppercase mb-2" }
const _hoisted_100 = { key: 0 }
const _hoisted_101 = { key: 1 }
const _hoisted_102 = { class: "flex flex-wrap mb-8" }
const _hoisted_103 = { class: "w-full font-bold uppercase mb-2" }
const _hoisted_104 = { key: 0 }
const _hoisted_105 = { key: 1 }
const _hoisted_106 = { class: "flex flex-wrap mb-1" }
const _hoisted_107 = { class: "w-full font-bold uppercase mb-2" }
const _hoisted_108 = { key: 0 }
const _hoisted_109 = { key: 1 }
const _hoisted_110 = {
  key: 0,
  class: "font-bold text-base"
}
const _hoisted_111 = { key: 0 }
const _hoisted_112 = { class: "relative block" }
const _hoisted_113 = { class: "pb-6" }
const _hoisted_114 = ["href"]
const _hoisted_115 = ["title"]
const _hoisted_116 = { class: "w-full lg:w-4/12 pl-10" }
const _hoisted_117 = { class: "flex justify-end items-center" }
const _hoisted_118 = { class: "px-2 py-2 text-sm text-gray-400 uppercase" }
const _hoisted_119 = { class: "bg-white border shadow-xl rounded-xl p-5 mb-5" }
const _hoisted_120 = { class: "pb-5" }
const _hoisted_121 = { class: "text-gray-500 text-xs font-bold py-2" }
const _hoisted_122 = { key: 0 }
const _hoisted_123 = { key: 0 }
const _hoisted_124 = { key: 0 }
const _hoisted_125 = { key: 1 }
const _hoisted_126 = { key: 0 }
const _hoisted_127 = { key: 1 }
const _hoisted_128 = { key: 1 }
const _hoisted_129 = { class: "flex text-gray-500 text-sm pb-5" }
const _hoisted_130 = { class: "font-semibold pb-3 pr-5" }
const _hoisted_131 = { key: 0 }
const _hoisted_132 = {
  key: 0,
  class: "flex flex-wrap"
}
const _hoisted_133 = ["title"]
const _hoisted_134 = {
  key: 0,
  class: "text-sm text-gray-400"
}
const _hoisted_135 = {
  key: 1,
  class: "flex flex-wrap gap-1"
}
const _hoisted_136 = ["title"]
const _hoisted_137 = ["href"]
const _hoisted_138 = {
  key: 0,
  class: "text-sm text-gray-400"
}
const _hoisted_139 = { key: 1 }
const _hoisted_140 = { class: "flex text-gray-500 text-sm pb-5" }
const _hoisted_141 = { class: "font-semibold pb-3 pr-5" }
const _hoisted_142 = { key: 0 }
const _hoisted_143 = { class: "font-normal px-2" }
const _hoisted_144 = {
  key: 0,
  target: "_blank",
  class: "text-gray-400",
  href: 'https://creativecommons.org/licenses/by/4.0/'
}
const _hoisted_145 = { class: "text-tethys-4 hover:underline pl-1" }
const _hoisted_146 = {
  key: 1,
  target: "_blank",
  class: "text-gray-400",
  href: 'https://creativecommons.org/licenses/by-sa/4.0/'
}
const _hoisted_147 = { class: "text-tethys-4 hover:underline pl-1" }
const _hoisted_148 = { class: "text-gray-500 pb-5" }
const _hoisted_149 = { class: "font-semibold pb-2 mb-3 border-b-2 border-gray-200" }
const _hoisted_150 = { key: 0 }
const _hoisted_151 = {
  key: 0,
  class: "flex flex-wrap gap-1"
}
const _hoisted_152 = {
  key: 1,
  class: "flex flex-wrap gap-1"
}
const _hoisted_153 = ["href", "title"]
const _hoisted_154 = { key: 1 }
const _hoisted_155 = { class: "text-gray-500 pb-5" }
const _hoisted_156 = { class: "font-semibold pb-2 mb-3 border-b-2 border-gray-200" }
const _hoisted_157 = { key: 0 }
const _hoisted_158 = ["title"]
const _hoisted_159 = { class: "relative w-full max-w-xl p-5 bg-white rounded-lg shadow" }
const _hoisted_160 = { class: "flex items-start justify-between p-4 mb-4 border-b rounded-t" }
const _hoisted_161 = { class: "text-sm font-bold text-gray-700 bg-white rounded-md border border-gray-300 shadow-sm px-2 py-1" }
const _hoisted_162 = { class: "font-semibold italic text-left pl-3 py-1" }
const _hoisted_163 = { class: "p-4 text-justify" }
const _hoisted_164 = { key: 1 }
const _hoisted_165 = { class: "text-gray-500 pb-5" }
const _hoisted_166 = { class: "font-semibold pb-2 mb-3 border-b-2 border-gray-200" }
const _hoisted_167 = { class: "bg-white border shadow-xl rounded-xl p-5 mb-5" }
const _hoisted_168 = { class: "font-semibold" }
const _hoisted_169 = { class: "border-t-2 border-gray-200 pt-4" }
const _hoisted_170 = { key: 0 }
const _hoisted_171 = { class: "pl-2 text-sm" }
const _hoisted_172 = { key: 0 }
const _hoisted_173 = ["href"]
const _hoisted_174 = {
  key: 1,
  class: "text-gray-700"
}
const _hoisted_175 = { key: 0 }
const _hoisted_176 = ["href"]
const _hoisted_177 = {
  key: 1,
  class: "block text-sm italic pb-2 pl-4"
}
const _hoisted_178 = { key: 1 }
const _hoisted_179 = { class: "bg-white border shadow-xl rounded-xl p-5 mb-8" }
const _hoisted_180 = { class: "font-semibold" }
const _hoisted_181 = { class: "border-t-2 border-gray-200 pt-4" }
const _hoisted_182 = { class: "font-semibold" }
const _hoisted_183 = { class: "font-semibold" }
const _hoisted_184 = { key: 0 }
const _hoisted_185 = { class: "font-semibold" }
const _hoisted_186 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vs_input = _resolveComponent("vs-input")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Minimap = _resolveComponent("Minimap")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vs_input, {
        propDisplay: _ctx.searchTerm,
        placeholder: _ctx.$t('enter_your_search_term'),
        onSearchChange: _ctx.onSearch
      }, null, 8, ["propDisplay", "placeholder", "onSearchChange"])
    ]),
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('published') + ": " + _ctx.getSimpleDate(_ctx.dataset.server_date_published)), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, [
                    _createElementVNode("span", {
                      class: "inline-block px-3 py-1 text-xs font-normal text-tethys-5 italic bg-tethys-3 rounded-xl shadow-sm",
                      title: _ctx.$t('data_type')
                    }, [
                      _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fas fa-file pr-1" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t(_ctx.dataset.type)), 1)
                    ], 8, _hoisted_11),
                    (_ctx.dataset.hasLicenses())
                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.licenses, (license) => {
                            return (_openBlock(), _createElementBlock("span", {
                              key: license.id
                            }, [
                              (_ctx.openAccessLicences.includes(license.name))
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: "inline-block px-3 mb-0.5 py-1 text-xs font-normal text-tethys-5 bg-tethys-3 rounded-xl shadow-sm ml-2 sm:ml-0",
                                    title: _ctx.$t('license_open')
                                  }, _cache[5] || (_cache[5] = [
                                    _createElementVNode("i", { class: "fas fa-lock-open" }, null, -1)
                                  ]), 8, _hoisted_13))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.dataset.hasEmbargoPassed())
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: "inline-block px-3 py-1 text-xs font-normal text-tethys-5 bg-tethys-3 rounded-xl shadow-sm ml-2 sm:ml-0",
                          title: _ctx.$t('dataset_under_embargo_icon')
                        }, _cache[6] || (_cache[6] = [
                          _createElementVNode("i", { class: "fa-solid fa-hourglass-half" }, null, -1)
                        ]), 8, _hoisted_14))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.dataset.hasOwnProperty('titles'))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                        _createElementVNode("span", _hoisted_16, [
                          (_ctx.dataset.MainTitle?.language.toUpperCase() == _ctx.$i18n.locale)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.dataset.MainTitle?.value), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_18, [
                                (_ctx.dataset.hasTranslatedTitle())
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.dataset.TranslatedTitle?.value), 1))
                                  : (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(_ctx.dataset.MainTitle?.value), 1))
                              ]))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", _hoisted_21, [
                    _createElementVNode("span", {
                      class: "px-2 py-1.5 text-xs font-bold text-gray-500 bg-gray-200 rounded shadow-sm",
                      title: _ctx.$t('data_type')
                    }, " DOI ", 8, _hoisted_22),
                    (_ctx.dataset.identifier)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          target: "_blank",
                          href: 'https://doi.org/' + _ctx.dataset.identifier.value,
                          class: "text-tethys-4 text-sm pl-2"
                        }, _toDisplayString("https://doi.org/" + _ctx.dataset.identifier.value), 9, _hoisted_23))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    (_ctx.dataset.authors.length > 0)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_25, [
                          _createElementVNode("span", _hoisted_26, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authorsList, (author, index) => {
                              return (_openBlock(), _createElementBlock("span", {
                                key: index,
                                class: "flex items-center"
                              }, [
                                _createElementVNode("span", null, _toDisplayString(author.displayName), 1),
                                (author.identifier_orcid != null)
                                  ? (_openBlock(), _createElementBlock("a", {
                                      key: 0,
                                      href: 'https://orcid.org/' + author.identifier_orcid,
                                      target: "_blank",
                                      class: "relative group pl-1 pr-0.5"
                                    }, [
                                      _cache[7] || (_cache[7] = _createElementVNode("img", {
                                        src: _imports_0,
                                        alt: "ORCID Logo",
                                        class: "w-4 h-4"
                                      }, null, -1)),
                                      _createElementVNode("span", _hoisted_28, _toDisplayString(author.displayName + " - " + _ctx.$t('visit_orcid')), 1)
                                    ], 8, _hoisted_27))
                                  : _createCommentVNode("", true),
                                (index < _ctx.authorsList.length - 1)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(";")))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_30, "-"))
                  ]),
                  (_ctx.dataset.hasOwnProperty('abstracts'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                        _createElementVNode("div", _hoisted_32, [
                          (_ctx.dataset.MainAbstract?.language.toUpperCase() == _ctx.$i18n.locale)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_33, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.dataset.MainAbstract?.value), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_34, [
                                (_ctx.dataset.hasTranslatedAbstract())
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_35, _toDisplayString(_ctx.dataset.TranslatedAbstract?.value), 1))
                                  : (_openBlock(), _createElementBlock("p", _hoisted_36, _toDisplayString(_ctx.dataset.MainAbstract?.value), 1))
                              ]))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_37, [
                    (_ctx.dataset.files.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                          _createElementVNode("div", _hoisted_39, [
                            _createElementVNode("table", _hoisted_40, [
                              _createElementVNode("thead", _hoisted_41, [
                                _createElementVNode("tr", _hoisted_42, [
                                  _createElementVNode("th", _hoisted_43, _toDisplayString(_ctx.$t('file')), 1),
                                  _createElementVNode("th", _hoisted_44, _toDisplayString(_ctx.$t('format')), 1),
                                  _createElementVNode("th", _hoisted_45, _toDisplayString(_ctx.$t('size')), 1),
                                  _createElementVNode("th", _hoisted_46, _toDisplayString(_ctx.$t('action')), 1)
                                ])
                              ]),
                              _createElementVNode("tbody", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.files, (file) => {
                                  return (_openBlock(), _createElementBlock("tr", {
                                    key: file.id,
                                    class: "hover:bg-gray-50 text-gray-600"
                                  }, [
                                    _createElementVNode("td", _hoisted_47, _toDisplayString(file.label), 1),
                                    _createElementVNode("td", _hoisted_48, [
                                      _createElementVNode("span", {
                                        class: "px-2 py-1 text-xs font-bold text-gray-400 bg-gray-200 rounded shadow-sm",
                                        title: _ctx.$t('data_type')
                                      }, _toDisplayString(_ctx.getExtension(file.path_name)), 9, _hoisted_49)
                                    ]),
                                    _createElementVNode("td", _hoisted_50, _toDisplayString(_ctx.formatSize(file.file_size)), 1),
                                    _createElementVNode("td", _hoisted_51, [
                                      (_ctx.dataset.hasEmbargoPassed())
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_52, [
                                            _createElementVNode("a", {
                                              class: "px-2 py-1.5 border border-tethys-4 shadow-sm bg-tethys-4 hover:bg-tethys-6 hover:border-tethys-6 text-white transition-colors rounded-md cursor-pointer",
                                              title: _ctx.$t('download_file'),
                                              target: "_blank",
                                              href: _ctx.portal + file.id
                                            }, _cache[8] || (_cache[8] = [
                                              _createElementVNode("i", { class: "fas fa-download text-xs pr-1" }, null, -1),
                                              _createTextVNode(" Download ")
                                            ]), 8, _hoisted_53),
                                            (_ctx.isFileSupported(_ctx.getExtension(file.path_name)))
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_54, [
                                                  _createElementVNode("span", {
                                                    class: _normalizeClass(['ml-1 px-2 py-1.5 border border-gray-300 shadow-sm hover:bg-gray-300 text-gray-500 transition-colors rounded-md cursor-pointer', 
                                                                    { 'text-tethys-4 bg-tethys-3 border-tethys-3': _ctx.fileLabel === file.label }]),
                                                    title: _ctx.$t('preview_file'),
                                                    onClick: ($event: any) => (_ctx.previewFile(file.label, _ctx.getExtension(file.path_name), _ctx.portal + file.id))
                                                  }, _cache[9] || (_cache[9] = [
                                                    _createElementVNode("i", { class: "fa-solid fa-eye text-xs pr-1" }, null, -1),
                                                    _createTextVNode(" View ")
                                                  ]), 10, _hoisted_55)
                                                ]))
                                              : (_openBlock(), _createElementBlock("span", _hoisted_56, [
                                                  _createElementVNode("span", {
                                                    class: "ml-1 px-2 py-1.5 border border-gray-300 shadow-sm text-gray-500 rounded-md cursor-not-allowed",
                                                    title: _ctx.$t('preview_not_supported')
                                                  }, _cache[10] || (_cache[10] = [
                                                    _createElementVNode("i", { class: "fa-solid fa-eye-slash text-xs pr-1" }, null, -1),
                                                    _createTextVNode(" View ")
                                                  ]), 8, _hoisted_57)
                                                ]))
                                          ]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ]))
                                }), 128))
                              ])
                            ]),
                            _createVNode(_component_Accordion, {
                              class: "block md:hidden pb-4",
                              customClass: "files-small-screen",
                              itemCount: 1
                            }, {
                              title: _withCtx(({ index }) => [
                                _createElementVNode("p", _hoisted_58, _toDisplayString(_ctx.$t('files')), 1)
                              ]),
                              content: _withCtx(({ index }) => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.files, (file) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: file.id,
                                    class: "border-b border-b-gray-300 mb-1 p-4"
                                  }, [
                                    _createElementVNode("div", _hoisted_59, [
                                      _createElementVNode("a", {
                                        target: "_blank",
                                        href: _ctx.portal + file.id,
                                        class: "text-primary-tethys font-bold hover:underline"
                                      }, [
                                        _cache[11] || (_cache[11] = _createElementVNode("i", { class: "fas fa-file-download mr-1" }, null, -1)),
                                        _createTextVNode(" " + _toDisplayString(file.label), 1)
                                      ], 8, _hoisted_60)
                                    ]),
                                    _createElementVNode("div", _hoisted_61, [
                                      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('format') + ': '), 1),
                                      _createTextVNode(" " + _toDisplayString(_ctx.getExtension(file.path_name)), 1)
                                    ]),
                                    _createElementVNode("div", null, [
                                      _createElementVNode("strong", null, _toDisplayString(_ctx.$t('size') + ': '), 1),
                                      _createTextVNode(" " + _toDisplayString(_ctx.formatSize(file.file_size)), 1)
                                    ])
                                  ]))
                                }), 128))
                              ]),
                              _: 1
                            }),
                            (!_ctx.dataset.hasEmbargoPassed())
                              ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                                  _createElementVNode("p", _hoisted_63, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('dataset_under_embargo') + ": ") + " ", 1),
                                    _createElementVNode("span", _hoisted_64, _toDisplayString(_ctx.getSimpleDate(_ctx.dataset.embargo_date)), 1),
                                    _cache[12] || (_cache[12] = _createTextVNode()),
                                    _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
                                    _createElementVNode("span", _hoisted_65, _toDisplayString(_ctx.$t('meanwhile_access_restricted')), 1)
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_Accordion, {
                    class: "hidden md:block px-5 pb-5",
                    customClass: "left-panel",
                    itemCount: 1,
                    ref: "previewAccordion"
                  }, {
                    title: _withCtx(({ index }) => [
                      _createElementVNode("div", _hoisted_66, [
                        _createTextVNode(_toDisplayString(_ctx.$t('preview_file') + ": ") + " ", 1),
                        (_ctx.fileLabel != '')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_67, _toDisplayString(_ctx.fileLabel + "." + _ctx.fileType), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    content: _withCtx(({ index }) => [
                      (_ctx.fileType === 'pdf')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                            _createElementVNode("iframe", {
                              src: _ctx.fileUrl,
                              width: "100%",
                              height: "500px",
                              frameborder: "0",
                              class: "border border-gray-300"
                            }, null, 8, _hoisted_69)
                          ]))
                        : (_ctx.fileType === 'csv' || _ctx.fileType === 'txt')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
                              _createElementVNode("div", _hoisted_71, [
                                _createElementVNode("table", _hoisted_72, [
                                  _createElementVNode("thead", _hoisted_73, [
                                    _createElementVNode("tr", null, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textHeaders, (header, idx) => {
                                        return (_openBlock(), _createElementBlock("th", {
                                          key: 'header-' + idx,
                                          scope: "col",
                                          class: "border-b border-gray-300 px-1 py-1 text-xs font-semibold text-gray-700 whitespace-normal"
                                        }, _toDisplayString(header), 1))
                                      }), 128))
                                    ])
                                  ]),
                                  _createElementVNode("tbody", null, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.textRows, (row, idx) => {
                                      return (_openBlock(), _createElementBlock("tr", {
                                        key: 'row-' + idx,
                                        class: "hover:bg-gray-100"
                                      }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (cell, i) => {
                                          return (_openBlock(), _createElementBlock("td", {
                                            key: 'cell-' + idx + '-' + i,
                                            class: "border-b border-gray-200 px-1 py-1 text-xs text-gray-600 whitespace-nowrap"
                                          }, _toDisplayString(cell), 1))
                                        }), 128))
                                      ]))
                                    }), 128))
                                  ]),
                                  (_ctx.fileType === 'txt')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_74, " ... only the first 100 lines are previewed ... "))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              (!_ctx.textHeaders.length)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_75, " No data available to display. "))
                                : _createCommentVNode("", true)
                            ]))
                          : (_ctx.fileType === 'jpg' || _ctx.fileType === 'png')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
                                _createElementVNode("img", {
                                  src: _ctx.fileUrl,
                                  alt: "Image Preview",
                                  class: "max-w-full border border-gray-300"
                                }, null, 8, _hoisted_77)
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_78, [
                                _createElementVNode("p", _hoisted_79, _toDisplayString(_ctx.$t('click_in_preview_icon')), 1)
                              ]))
                    ]),
                    _: 1
                  }, 512),
                  _createVNode(_component_Accordion, {
                    class: "px-5 pb-5",
                    itemCount: 1,
                    customClass: "left-panel"
                  }, {
                    title: _withCtx(({ index }) => [
                      (index === 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_80, _toDisplayString(_ctx.$t('further_details')), 1))
                        : _createCommentVNode("", true)
                    ]),
                    content: _withCtx(({ index }) => [
                      (index === 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
                            (_ctx.dataset.hasOwnProperty('abstracts'))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                                  _createElementVNode("span", _hoisted_83, _toDisplayString(_ctx.$t('method') + ':'), 1),
                                  (_ctx.dataset.hasMethodsAbstract())
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_84, [
                                        (_ctx.dataset.MethodsAbstract?.language.toUpperCase() == _ctx.$i18n.locale)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_85, [
                                              _createElementVNode("p", null, _toDisplayString(_ctx.dataset.MethodsAbstract?.value), 1)
                                            ]))
                                          : (_openBlock(), _createElementBlock("span", _hoisted_86, [
                                              (_ctx.dataset.hasTranslatedMethodsAbstract())
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_87, _toDisplayString(_ctx.dataset.TranslatedMethodsAbstract?.value), 1))
                                                : (_openBlock(), _createElementBlock("p", _hoisted_88, _toDisplayString(_ctx.dataset.MethodsAbstract?.value), 1))
                                            ]))
                                      ]))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_89, "-"))
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.dataset.hasOwnProperty('abstracts'))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_90, [
                                  _createElementVNode("span", _hoisted_91, _toDisplayString(_ctx.$t('series_information') + ':'), 1),
                                  (_ctx.dataset.hasSeriesInformationAbstract())
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_92, [
                                        (_ctx.dataset.SeriesInformationAbstract?.language.toUpperCase() == _ctx.$i18n.locale)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_93, [
                                              _createElementVNode("p", null, _toDisplayString(_ctx.dataset.SeriesInformationAbstract?.value), 1)
                                            ]))
                                          : (_openBlock(), _createElementBlock("span", _hoisted_94, [
                                              (_ctx.dataset.hasTranslatedSeriesInformationAbstract())
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_95, _toDisplayString(_ctx.dataset.TranslatedSeriesInformationAbstract?.value), 1))
                                                : (_openBlock(), _createElementBlock("p", _hoisted_96, _toDisplayString(_ctx.dataset.SeriesInformationAbstract?.value), 1))
                                            ]))
                                      ]))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_97, "-"))
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_98, [
                              _createElementVNode("span", _hoisted_99, _toDisplayString(_ctx.$t('publication_language') + ':'), 1),
                              (_ctx.getLanguage(_ctx.dataset.language) == 'Deutsch')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_100, _toDisplayString(_ctx.$t('german')), 1))
                                : (_openBlock(), _createElementBlock("span", _hoisted_101, _toDisplayString(_ctx.$t('english')), 1))
                            ]),
                            _createElementVNode("div", _hoisted_102, [
                              _createElementVNode("span", _hoisted_103, _toDisplayString(_ctx.$t('embargo') + ':'), 1),
                              (_ctx.dataset.embargo_date)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_104, _toDisplayString(_ctx.getHumanDate(_ctx.dataset.embargo_date)), 1))
                                : (_openBlock(), _createElementBlock("span", _hoisted_105, "-"))
                            ]),
                            _createElementVNode("div", _hoisted_106, [
                              _createElementVNode("span", _hoisted_107, _toDisplayString(_ctx.$t('contributor') + ':'), 1),
                              (_ctx.dataset.hasContributors())
                                ? (_openBlock(), _createElementBlock("span", _hoisted_108, _toDisplayString(_ctx.dataset.contributors.map((u) => u.full_name).join(", ")), 1))
                                : (_openBlock(), _createElementBlock("span", _hoisted_109, "-"))
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Accordion, {
                    class: "px-5 pb-6",
                    itemCount: 1,
                    customClass: "left-panel"
                  }, {
                    title: _withCtx(({ index }) => [
                      (index === 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_110, _toDisplayString(_ctx.$t('citation')), 1))
                        : _createCommentVNode("", true)
                    ]),
                    content: _withCtx(({ index }) => [
                      (index === 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_111, [
                            _createElementVNode("div", _hoisted_112, [
                              _createElementVNode("p", _hoisted_113, [
                                _createTextVNode(_toDisplayString(_ctx.getCitation()) + " ", 1),
                                (_ctx.dataset.identifier)
                                  ? (_openBlock(), _createElementBlock("a", {
                                      key: 0,
                                      target: "_blank",
                                      class: "text-secondary-tethys hover:underline",
                                      href: 'https://doi.org/' + _ctx.dataset.identifier.value
                                    }, " (" + _toDisplayString("https://doi.org/" + _ctx.dataset.identifier.value) + ") ", 9, _hoisted_114))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("span", {
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.dataset.identifier.value.toString()))),
                                class: "absolute bottom-0 right-0 cursor-pointer px-2 py-1 text-xs font-normal rounded-md border border-gray-300 shadow-sm hover:bg-gray-200",
                                title: _ctx.$t('copy_citation_tooltip')
                              }, [
                                _cache[14] || (_cache[14] = _createElementVNode("i", { class: "fa-regular fa-clone pr-1" }, null, -1)),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('copy_citation')), 1)
                              ], 8, _hoisted_115)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_116, [
                _createElementVNode("div", _hoisted_117, [
                  _createElementVNode("span", _hoisted_118, _toDisplayString(_ctx.$t('details')), 1)
                ]),
                _createElementVNode("div", _hoisted_119, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_Minimap, {
                      bounds: _ctx.dataset.Bounds,
                      class: "relative z-10 rounded-lg"
                    }, null, 8, ["bounds"])
                  ]),
                  _createElementVNode("div", _hoisted_120, [
                    _createElementVNode("h3", _hoisted_121, _toDisplayString(_ctx.$t('coverage')) + ":", 1),
                    _createVNode(_component_Accordion, {
                      customClass: "coverage",
                      itemCount: _ctx.coverageAttributes.length
                    }, {
                      title: _withCtx(({ index }) => [
                        _createTextVNode(_toDisplayString(_ctx.$t(_ctx.coverageAttributes[index].key)), 1)
                      ]),
                      content: _withCtx(({ index }) => [
                        (_ctx.coverageAttributes[index].values)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_122, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coverageAttributes[index].values, (value, label) => {
                                return (_openBlock(), _createElementBlock(_Fragment, { key: label }, [
                                  value
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_123, [
                                        (_ctx.coverageAttributes[index].key == 'geolocation')
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_124, _toDisplayString(_ctx.$t(label) + ": " + value.toFixed(6)), 1))
                                          : (_openBlock(), _createElementBlock("p", _hoisted_125, [
                                              (_ctx.coverageAttributes[index].key == 'elevation' || _ctx.coverageAttributes[index].key == 'depth')
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_126, _toDisplayString(_ctx.$t(label) + ": " + Intl.NumberFormat('de-de').format(value) + " m."), 1))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_127, _toDisplayString(_ctx.$t(label) + ": " + value), 1))
                                            ]))
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 128))
                            ]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_128, "No data available"))
                      ]),
                      _: 1
                    }, 8, ["itemCount"])
                  ]),
                  _createElementVNode("div", _hoisted_129, [
                    _createElementVNode("p", _hoisted_130, _toDisplayString(_ctx.$t('creator') + ':'), 1),
                    (_ctx.dataset.authors.length > 0)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_131, [
                          (_ctx.accessNotFromDoi())
                            ? (_openBlock(), _createElementBlock("span", _hoisted_132, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authorsList, (author, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: index,
                                    class: "pb-1 pr-1",
                                    title: _ctx.$t('search_creator')
                                  }, [
                                    _createVNode(_component_router_link, {
                                      to: { name: 'Search', params: { display: author.lastName, type: 'authors' } },
                                      class: "text-sm text-gray-400 hover:bg-gray-200 transition-colors"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(author.displayName), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["to"]),
                                    (index < _ctx.authorsList.length - 1)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_134, ";"))
                                      : _createCommentVNode("", true)
                                  ], 8, _hoisted_133))
                                }), 128))
                              ]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_135, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authorsList, (author, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: index,
                                    class: "pb-1 pr-1",
                                    title: _ctx.$t('search_creator')
                                  }, [
                                    _createElementVNode("a", {
                                      target: "_blank",
                                      href: _ctx.search_url + '/' + author.lastName + '/authors',
                                      class: "text-sm text-gray-400 hover:bg-gray-200 transition-colors"
                                    }, _toDisplayString(author.displayName), 9, _hoisted_137),
                                    (index < _ctx.authorsList.length - 1)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_138, ";"))
                                      : _createCommentVNode("", true)
                                  ], 8, _hoisted_136))
                                }), 128))
                              ]))
                        ]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_139, "-"))
                  ]),
                  _createElementVNode("div", _hoisted_140, [
                    _createElementVNode("p", _hoisted_141, _toDisplayString(_ctx.$t('license') + ':'), 1),
                    (_ctx.dataset.hasLicenses())
                      ? (_openBlock(), _createElementBlock("p", _hoisted_142, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.licenses, (license) => {
                            return (_openBlock(), _createElementBlock("label", {
                              key: license.id
                            }, [
                              _createElementVNode("span", _hoisted_143, [
                                (license.name=='CC-BY-4.0')
                                  ? (_openBlock(), _createElementBlock("a", _hoisted_144, [
                                      _cache[15] || (_cache[15] = _createElementVNode("i", { class: "fa-brands fa-creative-commons" }, null, -1)),
                                      _createElementVNode("span", _hoisted_145, _toDisplayString(license.name), 1)
                                    ]))
                                  : (_openBlock(), _createElementBlock("a", _hoisted_146, [
                                      _cache[16] || (_cache[16] = _createElementVNode("i", { class: "fa-brands fa-creative-commons" }, null, -1)),
                                      _createElementVNode("span", _hoisted_147, _toDisplayString(license.name), 1)
                                    ]))
                              ])
                            ]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_148, [
                    _createElementVNode("p", _hoisted_149, _toDisplayString(_ctx.$t('keywords') + ':'), 1),
                    (_ctx.dataset.hasOwnProperty('subjects'))
                      ? (_openBlock(), _createElementBlock("p", _hoisted_150, [
                          (_ctx.accessNotFromDoi())
                            ? (_openBlock(), _createElementBlock("span", _hoisted_151, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.subjects, (subject, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: subject.value,
                                    class: "pb-2 flex-shrink-0"
                                  }, [
                                    _createVNode(_component_router_link, {
                                      to: { name: 'Search', params: { display: subject.value, type: 'subjects' } },
                                      class: "px-2 py-1 text-xs text-tethys-5 bg-tethys-2 rounded-xl border border-tethys-2 shadow-sm hover:bg-tethys-6 hover:text-white hover:border-tethys-6 transition-colors",
                                      title: _ctx.$t('search_keyword')
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(subject.value), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["to", "title"])
                                  ]))
                                }), 128))
                              ]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_152, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.subjects, (subject, index) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: subject.value,
                                    class: "pb-2 flex-shrink-0"
                                  }, [
                                    _createElementVNode("a", {
                                      target: "_blank",
                                      href: _ctx.search_url + '/' + subject.value + '/subjects',
                                      class: "px-2 py-1 text-xs text-tethys-5 bg-tethys-2 rounded-xl border border-tethys-2 shadow-sm hover:bg-tethys-6 hover:text-white hover:border-tethys-6 transition-colors",
                                      title: _ctx.$t('search_keyword')
                                    }, _toDisplayString(subject.value), 9, _hoisted_153)
                                  ]))
                                }), 128))
                              ]))
                        ]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_154, "-"))
                  ]),
                  _createElementVNode("div", _hoisted_155, [
                    _createElementVNode("p", _hoisted_156, _toDisplayString(_ctx.$t('project') + ':'), 1),
                    (_ctx.dataset.project != null)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_157, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.dataset.project.label), 1),
                          _createElementVNode("i", {
                            class: "fas fa-circle-info text-tethys-6 hover:text-black pl-1",
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showModal && _ctx.showModal(...args))),
                            title: _ctx.$t('project_details')
                          }, null, 8, _hoisted_158),
                          (_ctx.isShowModal)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["self"])),
                                class: "fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50",
                                "aria-hidden": "true"
                              }, [
                                _createElementVNode("div", _hoisted_159, [
                                  _createElementVNode("div", _hoisted_160, [
                                    _createElementVNode("span", _hoisted_161, _toDisplayString(_ctx.dataset.project?.label), 1),
                                    _createElementVNode("p", _hoisted_162, _toDisplayString(_ctx.dataset.project?.name), 1),
                                    _createElementVNode("button", {
                                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
                                      type: "button",
                                      class: "text-gray-400 hover:text-gray-900 py-1"
                                    }, _cache[17] || (_cache[17] = [
                                      _createElementVNode("svg", {
                                        class: "w-5 h-5",
                                        fill: "currentColor",
                                        viewBox: "0 0 20 20",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }, [
                                        _createElementVNode("path", {
                                          "fill-rule": "evenodd",
                                          d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                                          "clip-rule": "evenodd"
                                        })
                                      ], -1)
                                    ]))
                                  ]),
                                  _createElementVNode("div", _hoisted_163, [
                                    _createElementVNode("p", null, _toDisplayString(_ctx.dataset.project.description), 1)
                                  ])
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_164, "-"))
                  ]),
                  _createElementVNode("div", _hoisted_165, [
                    _createElementVNode("p", _hoisted_166, _toDisplayString(_ctx.$t('publisher') + ':'), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.dataset.publisher_name), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_167, [
                  _createVNode(_component_Accordion, {
                    class: "",
                    customClass: "right-panel",
                    itemCount: 1,
                    ref: "referencesAccordion"
                  }, {
                    title: _withCtx(({ index }) => [
                      _createElementVNode("div", _hoisted_168, _toDisplayString(_ctx.$t('references')), 1)
                    ]),
                    content: _withCtx(({ index }) => [
                      _createElementVNode("div", _hoisted_169, [
                        (_ctx.dataset.references.length> 0)
                          ? (_openBlock(), _createElementBlock("ul", _hoisted_170, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset.references, (reference, i) => {
                                return (_openBlock(), _createElementBlock("li", {
                                  key: reference.id,
                                  class: "pb-2"
                                }, [
                                  _createElementVNode("p", null, [
                                    _cache[18] || (_cache[18] = _createElementVNode("i", { class: "fa-solid fa-right-to-bracket text-tethys-6 pb-1" }, null, -1)),
                                    _createElementVNode("span", _hoisted_171, _toDisplayString(_ctx.$t(reference.relation) + ":"), 1)
                                  ]),
                                  (reference.type == 'DOI' || reference.type == 'URL')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_172, [
                                        _createElementVNode("a", {
                                          target: "_blank",
                                          class: "block text-sm italic text-tethys-4 hover:underline pb-2 pl-5",
                                          href: reference.value
                                        }, _toDisplayString(reference.label), 9, _hoisted_173)
                                      ]))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_174, [
                                        (reference.value.indexOf('85316') != -1 || reference.value.indexOf('900312') != -1)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_175, [
                                              _createElementVNode("a", {
                                                target: "_blank",
                                                class: "block text-sm italic text-tethys-4 hover:underline pb-2 pl-4",
                                                href: 'https://bibliothek.geosphere.at/cgi-bin/koha/opac-search.pl?idx=&q=' + reference.value
                                              }, _toDisplayString(`(${reference.type}): ${reference.value}`), 9, _hoisted_176)
                                            ]))
                                          : (_openBlock(), _createElementBlock("span", _hoisted_177, _toDisplayString(`(${reference.type}): ${reference.value}`), 1))
                                      ]))
                                ]))
                              }), 128))
                            ]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_178, _toDisplayString(_ctx.$t('no_linked_references')), 1))
                      ])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _createElementVNode("div", _hoisted_179, [
                  _createVNode(_component_Accordion, {
                    class: "",
                    customClass: "right-panel",
                    itemCount: 1
                  }, {
                    title: _withCtx(({ index }) => [
                      _createElementVNode("div", _hoisted_180, _toDisplayString(_ctx.$t('technical_metadata')), 1)
                    ]),
                    content: _withCtx(({ index }) => [
                      _createElementVNode("div", _hoisted_181, [
                        _createElementVNode("p", null, [
                          _createElementVNode("span", _hoisted_182, _toDisplayString(_ctx.$t('persistent_identifier') + ': '), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.dataset.url), 1)
                        ]),
                        _createElementVNode("p", null, [
                          _createElementVNode("span", _hoisted_183, _toDisplayString(_ctx.$t('status') + ': '), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.dataset.server_state), 1)
                        ]),
                        (_ctx.dataset.hasOwnProperty('user'))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_184, [
                              _createElementVNode("span", _hoisted_185, _toDisplayString(_ctx.$t('posted_by') + ': '), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.dataset.user.login), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("p", null, [
                          _createElementVNode("span", _hoisted_186, _toDisplayString(_ctx.$t('editor') + ': '), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.dataset.creating_corporation), 1)
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}