<template>
  <div :class="['accordion', customClass]">
    <div v-for="index in itemCount" :key="index" class="accordion-item" :class="customClass">
      <button
        class="accordion-header"
        :class="customClass"
        @click="toggleAccordion(index - 1)"
      >
        <!-- v-for="index in itemCount" starts counting from 1, but array-like indices in JavaScript and Vue usually start from 0. 
        Subtracting 1 ensures that the index aligns correctly with zero-based logic. -->

        <!-- class="arrow-icon w-4 h-4 transition-transform duration-300"
        :class="{ 'rotate-180': isActive(index - 1) }" -->

        <!-- class="arrow-icon w-4 h-4 transition-transform duration-300"
        :class="{ 'rotate-180': isActive(index - 1) }" -->
        
        <!-- Title slot -->
        <slot name="title" :index="index - 1"></slot>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :class="[
            'arrow-icon w-4 h-4 transition-transform duration-300',
            customClass,
            { 'rotate-180': isActive(index - 1) }
          ]"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          :stroke-width="customClass == 'left-panel' || 'right-panel' ? 4 : 2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <div
        class="accordion-body"
        :class="customClass"
        v-show="isActive(index - 1)"
      >
        <!-- Content slot -->
        <slot name="content" :index="index - 1"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Accordion from "./Accordion";
export default Accordion;
</script>

<style >
.accordion-header {
  @apply flex justify-between items-center w-full px-4 py-2 font-medium text-left text-gray-900 bg-white border rounded shadow focus:ring-2 focus:ring-gray-200;
}
.accordion-body {
  @apply px-4 py-2 border border-gray-200 overflow-auto;
}
.arrow-icon {
  @apply ml-2 text-gray-500;
}

/* Custom variant styles =====================================================  */

.right-panel.accordion-header {
  @apply flex justify-between items-baseline w-full font-sans px-0 py-0 shadow-none border-none text-gray-500 focus:ring-0;
}

.right-panel.accordion-body {
  @apply font-sans text-gray-500 border-transparent text-sm px-0 py-2 overflow-auto;
}

.coverage.accordion-header {
  @apply flex justify-between items-center w-full font-sans text-xs border-gray-200 shadow-none px-4 py-1 text-gray-500 bg-white focus:ring-2 focus:ring-gray-200 rounded-md;
}

.coverage.accordion-body {
  @apply font-sans text-gray-500 text-xs border-gray-100 px-4 py-1 mx-2 overflow-auto;
}

.left-panel.accordion-header {
  @apply flex w-full h-12 justify-between items-center font-sans text-sm border-gray-100 shadow-none px-4 py-1 text-gray-500 bg-gray-100 focus:ring-2 focus:ring-gray-200 rounded-lg;
}

.left-panel.accordion-body {
  @apply font-sans text-gray-500 border-transparent text-sm px-4 py-5 mx-2 overflow-auto;
}


/* .left-panel.arrow-icon {
  @apply text-blue-500;
} */


/* .coverage.arrow-icon {
  @apply text-red-500 bg-red-600 !important;
} */

.files-small-screen.accordion-header {
  @apply flex justify-between items-center w-full px-4 py-2 font-medium text-left text-gray-900 bg-gray-100 border rounded shadow focus:ring-2 focus:ring-gray-200;
}

</style>
