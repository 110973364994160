<template>
    <!-- <div id="page_style" class="flex flex-col items-center w-full h-full bg-transparent overflow-hidden" autocomplete="off"> -->
    <div class="flex flex-col items-center w-full h-full bg-tethys-1 overflow-hidden" autocomplete="off">
        <!-- Search input section -->
        <!-- <div class="search-banner w-full bg-cover bg-no-repeat py-24 min-h-[400px] bg-[position:50%_25%]"> -->
        <!-- <div class="search-banner w-full bg-cover bg-no-repeat min-h-[412px] bg-[position:50%_50%]"> -->
        <div class="search-banner w-full bg-cover bg-no-repeat min-h-64 lg:min-h-[350px] bg-[position:50%_50%] relative before:absolute before:top-0 before:left-0 before:w-full before:h-4 before:bg-gradient-to-b before:from-black 
        before:to-transparent before:opacity-20 after:absolute after:bottom-0 after:left-0 after:w-full after:h-4 after:bg-gradient-to-t after:from-black after:to-transparent after:opacity-20">
            <vs-input v-bind:propDisplay="searchTerm" v-bind:placeholder="$t('enter_your_search_term')" @search-change="onSearch"></vs-input>
        </div>

        <div id="filters" class="w-5/6 2xl:w-4/6 h-40 md:h-24 2xl:h-16 3xl:h-20 bg-tethys-1">
            <FacetsComponent :facets="facets" :sortByAttribute="sortByAttribute" :sortByOrder="sortByOrder" :hasSearchTerm="hasSearchTerm" @filter="onFilter" @clearFacet="onClearFacetCategory" @changeOrdering="onChangeOrdering"></FacetsComponent>
        </div>

        <!-- Results area on top of the list of publications for non-mobile devices -->
        <div class="hidden sm:flex w-5/6 2xl:w-4/6">
            <!-- Section for Count of results  -->
            <div class="w-full bg-white h-11 rounded-md flex mb-4 pl-3 items-center justify-between">
                <!-- Display message if a search term exists -->
                <div v-if="hasSearchTerm" class="px-2 py-1 text-xs bg-white rounded-md" role="alert">
                    <span class="font-medium">{{ $t('your_search_term') }}</span>
                    <span class="font-semibold">{{ stringSearchTerm }}</span>{{ $t('yielded') }}
                    <strong>{{ results.length > 0 ? numFound : 0 }}</strong> {{ $t('results') }}:
                </div>
                <!-- Pagination before search results -->
                <PaginationTop class="pr-2 ml-auto" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationTop>
            </div>
        </div>

        <!-- MOBILE DEVICES ONLY: Results area on top of the results ==================================================  -->
        <div class="sm:hidden w-5/6">

            <!-- MOBILE DEVICES ONLY: Section for Count of results -->
            <div class="bg-[#eff3f7] rounded-md p-2 my-4 flex items-center justify-between">
                <div class="px-2 py-1 text-xs bg-white rounded-md" role="alert">
                    <!-- Display the complete message if a search term exists -->
                    <span v-if="hasSearchTerm">
                        {{ $t('your_search_term') }}
                        <span class="font-semibold">{{stringSearchTerm}}</span>
                        {{ $t('yielded') + " "}}
                    </span> 
                    <strong>{{ results.length > 0 ? numFound : 0 }}</strong> results
                </div>
            </div>

            <!-- MOBILE DEVICES ONLY: Area on top of the list of publications -->
            <!-- Section for Count of results -->
            <div class="bg-[#eff3f7] rounded-md p-2 my-4 flex items-center justify-between">
                <!-- Pagination before search results -->
                <PaginationTop class="pr-2 ml-auto" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationTop>
            </div>

        </div>

        <!-- Area to display results -->
        <div class="flex flex-col md:flex-row w-10/12 xl:w-5/6 2xl:w-4/6">
            <div>
                <!-- Results section ================================================================================================= -->
                <vs-result v-bind:datasets="results"></vs-result>
            </div>
        </div>

        <!-- MOBILE DEVICES ONLY: Results area below the list of publications -->
        <div class="flex sm:hidden w-5/6">
            <!-- Section for results information -->
            <div class="bg-[#eff3f7] h-11 rounded-md my-3 flex w-full items-center justify-between">
                <!-- Button to go up to the top -->
                <a
                    class="inline-block px-2 py-1 ml-2 mr-1 border border-gray-300 shadow-sm hover:bg-gray-300 transition-colors text-xs font-normal text-primary-tethys rounded cursor-pointer"
                    :title="$t('go_to_search_bar')"
                >
                    <i class="fas fa-arrow-up" @click="scrollToTop()"></i>
                </a>
                <!-- Pagination after search results (Despite the component name, in mobile devices it comes below the results) -->
                <PaginationTop class="pr-2 ml-auto" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationTop>
            </div>
        </div>

        <!-- Pages-found area below the list of publications for non-mobile devices -->
        <div class="hidden sm:flex w-5/6 2xl:w-4/6 pb-10">
            <!-- Section for pagination element (RIGHT) -->
            <div class="w-full h-11 rounded-md my-4 flex items-center justify-between">
                <!-- Pagination List after search results -->
                <PaginationBase v-bind:data="pagination" @menu-click="onMenuClick"></PaginationBase>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import PaginationBase from "@/components/PaginationBase.vue";
import SearchViewComponent from "./search-view-component";
export default SearchViewComponent;
// Import Tailwind CSS
import "@/index.css";
</script>

<style scoped>
.search-banner {
    background-image: url('/src/assets/site/img/main-search-banner.jpg');
    /* box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.2), inset 0 -5px 5px rgba(0, 0, 0, 0.2); */
}

</style>
