import { FacetItem, FacetResults } from "@/models/headers";
import { Component, Vue, Prop, Emit } from "vue-facing-decorator";
// import { Watch } from "vue-facing-decorator";

@Component({
    name: "FacetsComponent",
})
export default class FacetsComponent extends Vue {
    openDropdown: string | null = null;
    searchQueries: Record<string, string> = {};

    /** Define fixed facet keys 
     * These facets will always be displayed in the same order
     * The names correspond to the facets stored in the OpenSearch response, 
     * despite we decided to use a different name in the UI for some of them
     * The translation library will take care of the use of the correct name
    */
    fixedFacets: string[] = ["doctype", "year", "author", "subjects", "language"];

    @Prop({ type: FacetResults, required: true })
    facets!: FacetResults;

    @Prop({ type: Boolean, required: true })
    hasSearchTerm!: boolean;

    @Prop({ type: String, required: true })
    sortByAttribute!: string;

    @Prop({ type: String, required: true })
    sortByOrder!: string;

    // watchHasSearchTerm(newVal: boolean) {
    //     console.log("Search term changed:", newVal);
    // }

    // @Watch("hasSearchTerm")
    // onHasSearchTermChanged(newVal: boolean) {
    //     this.watchHasSearchTerm(newVal);
    // }

    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    }

    beforeUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }

    handleClickOutside(event: Event) {
        const target = event.target as HTMLElement;
        // if (!target.closest(".facet-dropdown")) {
        //     this.openDropdown = null;
        // }
        if (!target.closest(".facet-dropdown") && !target.closest(".allFilters-dropdown") && !target.closest(".sort-dropdown")) {
            this.openDropdown = null;
        }
    }

    toggleDropdown(facetKey: string) {
        this.openDropdown = this.openDropdown === facetKey ? null : facetKey;
        if (this.openDropdown !== null && !this.searchQueries[this.openDropdown]) {
            this.searchQueries[this.openDropdown] = "";
        }
        // console.log(this.sortByAttribute, this.sortByOrder);
    }

    // getFacetKeys() {
    //     return Object.keys(this.facets);
    // }

    filteredOptions(facetKey: string) {
        // console.log(facetKey);
        const query = this.searchQueries[facetKey]?.toLowerCase() || "";
        // return (this.facets[facetKey] || []).filter(option =>
        //     option.val.toString().toLowerCase().includes(query)
        // );
        return this.facets[facetKey].filter(option =>
            // console.log(option)
            option.val.toString().toLowerCase().includes(query)
        );
    }

    
    // toggleSelection(facetKey: string, option: FacetItem) {
    //     console.log("toggleSelection", facetKey, option);
        
    //     option.active = !option.active;
    // }

    @Emit("filter")
    toggleSelection(facetKey: string, option: FacetItem): FacetItem {
        // console.log("toggleSelection, emits onFilter");
        option.category = facetKey;
        option.active = !option.active;
        return option;
    }

// @Emit("filter")
// test(option: FacetItem) {
//     console.log("test", option);
// }

    @Emit("filter")
    removeSelection(facetKey: string, option: FacetItem): FacetItem {
        option.active = false;
        return option;
    }

    
    // isSelected(option: FacetItem) {
    //     console.log("isSelected", option);
        
    //     return option.active;
    // }

    /** Clears the search text inside a specific facet */
    clearSearch(facetKey: string) {
        this.searchQueries[facetKey] = "";
    }

    /** Reset all selected filters for a specific facet */
    @Emit("clearFacet")
    clearFacet(facetKey: string): string {

        // if (this.facets[facetKey]) {
        //     this.facets[facetKey].forEach(item => item.active = false);
        // }

        this.facets[facetKey].forEach(item => item.active = false);
        this.searchQueries[facetKey] = "";
        return facetKey;
    }

    /** Reset all filters across all facets */
    @Emit("clearFacet")
    resetFilters() {
        Object.keys(this.facets).forEach(key => {
            this.facets[key].forEach(item => item.active = false);
        });
        this.searchQueries = {}; // Clear all search inputs
        this.openDropdown = null; // Close all dropdowns
    }

    /** Reset all filters across all facets */
    @Emit("changeOrdering")
    changeOrdering(sortingAttribute: string, sortingOrder: string): [string, string] { 
        console.log("Ordering changed");
        return [sortingAttribute, sortingOrder];
    }

    get selectedTags() {
        return Object.values(this.facets).flat().filter(item => item.active);
    }
}
