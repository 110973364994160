import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["stroke-width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['accordion', _ctx.customClass])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemCount, (index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(["accordion-item", _ctx.customClass])
      }, [
        _createElementVNode("button", {
          class: _normalizeClass(["accordion-header", _ctx.customClass]),
          onClick: ($event: any) => (_ctx.toggleAccordion(index - 1))
        }, [
          _renderSlot(_ctx.$slots, "title", {
            index: index - 1
          }),
          (_openBlock(), _createElementBlock("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            class: _normalizeClass([
            'arrow-icon w-4 h-4 transition-transform duration-300',
            _ctx.customClass,
            { 'rotate-180': _ctx.isActive(index - 1) }
          ]),
            fill: "none",
            viewBox: "0 0 24 24",
            stroke: "currentColor",
            "stroke-width": _ctx.customClass == 'left-panel' || 'right-panel' ? 4 : 2
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("path", {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              d: "M19 9l-7 7-7-7"
            }, null, -1)
          ]), 10, _hoisted_2))
        ], 10, _hoisted_1),
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(["accordion-body", _ctx.customClass])
        }, [
          _renderSlot(_ctx.$slots, "content", {
            index: index - 1
          })
        ], 2), [
          [_vShow, _ctx.isActive(index - 1)]
        ])
      ], 2))
    }), 128))
  ], 2))
}