import { Component, Vue, Watch } from "vue-facing-decorator";
import HomeViewComponent from "./views/home-view/home-view-component.vue";
import HelpViewComponent from "./views/help-view/help-view-component.vue";
import MapViewComponent from "./views/map-view/map-view.component.vue";
import SearchViewComponent from "./views/search-view/search-view-component.vue";
import DatasetDetailComponent from "./views/dataset-detail.component/dataset-detail.component.vue";
import ServiceViewComponent from "./views/services-view/service-view-component.vue";
import OaiViewComponent from "./views/oai-view/oai-view-component.vue";
import ContactViewComponent from "./views/contact-view/contact-view-component.vue";
import SitelinkViewComponent from "./views/sitelink-view/sitelink-view-component.vue";
import ImprintViewComponent from "./views/imprint-view/imprint-view-component.vue";
import TermsViewComponent from "./views/terms-view/terms-view-component";

import { APP_URL } from "./constants";

@Component({
    components: {
        HomeViewComponent,
        HelpViewComponent,
        MapViewComponent,
        SearchViewComponent,
        DatasetDetailComponent,
        ServiceViewComponent,
        OaiViewComponent,
        ContactViewComponent,
        SitelinkViewComponent,
        ImprintViewComponent,
        TermsViewComponent,
    },
})
export default class App extends Vue {
    public active = false;
    public portal = "https://data.tethys.at/login"; // VUE_API + "/login";

    public app_url = APP_URL;
    public search_url = APP_URL + "/search";
    public service_url = APP_URL + "/services";
    public help_url = APP_URL + "/help";
    public oai_url = APP_URL + "/oai";
    
    dropdownOpen = false;

    menuRoutes = [
        { path: "/", label: "home" },
        { path: "/search", label: "search" },
        { path: "/services", label: "services" },
        { path: "/help", label: "help" },
        { path: "/oai", label: "OAI" },
    ];

    isActive(routePath: string) {
        // console.log("Active:" + routePath);
        
        return this.$route.path === routePath;
    }

    /**
     * Computed property that returns the current year.
     * @returns {number} The current year as a number.
     */
    get currentYear() {
        return new Date().getFullYear();
    }

    /**
     * Lifecycle hook called when the component is mounted.
     * Currently empty, but can be used to add setup logic when the component is mounted.
     */
    mounted(): void {

    }

    /**
     * Toggles the visibility of the mobile menu.
     * @param {MouseEvent} event - The mouse event triggered by the user's interaction.
     */
    public showMobilemenu(event: MouseEvent): void {
        // Prevent the default behavior of the event (e.g., following a link)
        // event.preventDefault();
        // Toggle the active state of the mobile menu
        this.active = !this.active;
        console.log("active: " + this.active);
    }

    /**
     * Watcher that triggers when the route changes.
     * It deactivates the mobile menu by setting `active` to false.
     */
    @Watch("$route")
    protected oRouteChangedChanged(): void {
        // Close the mobile menu when the route changes
        this.active = false;
    }

    /**
     * Changes the current locale to the selected locale.
     * @param locale - The new locale to switch to (e.g., 'en', 'de').
     */
    public changeLocale(locale: string): void {
        this.$i18n.locale = locale;
        this.dropdownOpen = false; // Close dropdown after selection
    }
}
