import { createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "w-5/6 2xl:w-4/6 pt-8 mx-auto relative" }
const _hoisted_3 = { class: "relative flex items-center" }
const _hoisted_4 = { class: "h-20 px-3 relative flex w-full items-center bg-gray-100 bg-opacity-75 shadow-md shadow-gray-500 border-gray-300 rounded-xl border-y border-l" }
const _hoisted_5 = ["title"]
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "absolute w-full z-50 border-x-8 border-transparent" }
const _hoisted_9 = { class: "bg-gray-200 bg-opacity-80 border border-gray-300 rounded-b-lg max-h-[198px] overflow-y-auto shadow-xl w-full" }
const _hoisted_10 = {
  key: 0,
  class: "p-2 text-gray-500"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "text-sm" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 0,
  class: "mt-3.5 bg-tethys-6 hover:bg-tethys-7 text-white font-bold py-2.5 px-6 rounded-lg shadow-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "h-14 z-0 pl-5 pr-4 text-blue-300 rounded-s-lg bg-white",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.search())),
            title: _ctx.$t('search')
          }, _cache[9] || (_cache[9] = [
            _createElementVNode("i", { class: "fas fa-search text-2xl" }, null, -1)
          ]), 8, _hoisted_5),
          _cache[11] || (_cache[11] = _createElementVNode("span", { class: "flex items-center h-14 bg-white" }, [
            _createElementVNode("span", { class: "h-3/4 border-r-2 border-gray-200" })
          ], -1)),
          _withDirectives(_createElementVNode("input", {
            id: "search_query",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.display) = $event)),
            class: "h-14 w-full py-2.5 text-gray-700 placeholder-gray-400 border-none focus:ring-0 focus:outline-none rounded-e-xl",
            type: "text",
            name: "q",
            autocomplete: "off",
            placeholder: _ctx.placeholder,
            onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.searchChanged && _ctx.searchChanged(...args))),
            onKeydown: [
              _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onArrowDown && _ctx.onArrowDown(...args)), ["down"])),
              _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onArrowUp && _ctx.onArrowUp(...args)), ["up"])),
              _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
              _cache[6] || (_cache[6] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["tab"]))
            ],
            onFocus: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args)))
          }, null, 40, _hoisted_6), [
            [_vModelText, _ctx.display]
          ]),
          (_ctx.display)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "absolute right-6 px-4 py-2 border-x-2 border-y-2 border-gray-100 text-gray-400 hover:bg-gray-200",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.clearSearch())),
                title: _ctx.$t('clean_search_bar')
              }, _cache[10] || (_cache[10] = [
                _createElementVNode("i", { class: "fas fa-times" }, null, -1)
              ]), 8, _hoisted_7))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _withDirectives(_createElementVNode("ul", _hoisted_9, [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("li", _hoisted_10, "Loading results..."))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.suggestions, (result, i) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: i,
                  ref_for: true,
                  ref: _ctx.setItemRef,
                  class: _normalizeClass(["py-1 px-3 cursor-pointer hover:bg-teal-100", { 'bg-teal-200': _ctx.isSelected(i) }]),
                  onClick: _withModifiers(($event: any) => (_ctx.select(result)), ["prevent"])
                }, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("label", {
                      innerHTML: _ctx.formatSuggestion(result)
                    }, null, 8, _hoisted_13)
                  ])
                ], 10, _hoisted_11))
              }), 128))
        ], 512), [
          [_vShow, _ctx.showResults]
        ])
      ]),
      (!_ctx.display)
        ? (_openBlock(), _createElementBlock("button", _hoisted_14, _toDisplayString(_ctx.$t('submit')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}