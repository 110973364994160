<template>

    <!-- <nav class="navbar navbar-light border-bottom" role="navigation" aria-label="main navigation"> -->
    <nav class="flex flex-wrap justify-between h-32 font-bold text-sm border-b border-gray-200 bg-white py-3" role="navigation" aria-label="main navigation">
        
        <!-- LEFT Section: Logo & Text -->
        <!-- <div class="flex items-center space-x-2 pl-4"> -->
        <div class="flex items-center space-x-2">
            <a class="flex items-center" href="/">
                <!-- <img src="./assets/images/tethys_new_logo_temporary.png" class="h-14 xl:h-16 pl-5" /> -->
                <img src="./assets/images/tethys_new_logo_temporary.png" class="h-14 xl:h-16 pl-28" />
            </a>

            <!-- Separator -->
            <span class="hidden md:block h-12 lg:h-16 border border-gray-400"></span>

            <div class="hidden md:flex flex-col pl-3">
                <span class="text-gray-500 text-sm xl:text-lg 2xl:text-xl font-normal">GeoSphere Tethys</span>
                <span class="text-gray-400 text-xs xl:text-sm font-normal">Geoscientific research data publisher</span>
            </div>
        </div>

<!-- =========================== RESTORE THIS TO NAVIGATE WHILE FINAL UI IS NOT READY  =========================== -->

        <!-- CENTER Section: Navigation Menu -->
        <!-- <div 
            class="hidden md:flex md:items-end md:justify-center"
        >
            <ul class="flex 2xl:space-x-4 text-xs 2xl:text-sm text-gray-800 uppercase">
                <li v-for="(route, index) in menuRoutes" :key="index">
                    <router-link 
                        class="px-4 py-2 transition border-b-2 border-transparent text-gray-800 hover:border-secondary-tethys hover:bg-white hover:text-gray-800"
                        :class="{ 'px-4 py-2 border-b-2 border-b-secondary-tethys bg-white font-bold': isActive(route.path) }"
                        :to="route.path"
                    >
                        {{ $t(route.label) }}
                    </router-link>
                </li>
            </ul>
        </div> -->

<!-- ====================================================== -->


        <!-- MOBILE Menu Button -->
        <button 
            class="md:hidden flex items-center px-2 border rounded text-gray-600 border-gray-200 hover:text-gray-800 hover:border-gray-400 hover:bg-gray-100 mr-2"
            @click="showMobilemenu"
            aria-label="Toggle navigation"
        >
            <svg class="h-6 w-6" fill="none" stroke="currentColor" stroke-width="2" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
        </button>


        <!-- RIGHT Section: AboutUs icon, Login and Language Selector -->
        <!-- <div class="hidden md:flex items-end space-x-2 xl:space-x-3 2xl:space-x-5 lg:pr-2 xl:pr-5 2xl:pb-5"> -->
        <div class="hidden md:flex items-end space-x-2 xl:space-x-3 2xl:space-x-5 lg:pr-5 xl:pr-16 2xl:pb-5">

            <!-- About Us button -->
            <a 
                v-bind:href="'https://gis.geosphere.at/tethys'" 
                target="_self"
                class="flex items-center py-2 px-2 text-gray-800 hover:bg-gray-100 focus:outline-none rounded-md"
            >
                <i class="fa-solid fa-circle-question"></i>
            </a>

            <!-- Login button -->
            <a 
                v-bind:href="portal" 
                target="_blank"
                class="flex items-center py-1 px-2 text-gray-800 hover:bg-gray-100 focus:outline-none rounded-md"
            >
                <strong>{{ $t("login") }}</strong>
            </a>

            <!-- Separator -->
            <div class="h-8 w-0.5 bg-gray-800"></div>

            <!-- Language Selection -->
            <div class="relative">
                <button 
                    class="flex space-x-2 items-center py-1 px-2 text-gray-800 hover:bg-gray-100 focus:outline-none rounded-md" 
                    :title="$t('select_details_language')"
                    @click="dropdownOpen = !dropdownOpen"
                >
                    <span class="text-sm 2xl:text-md font-bold">{{ $i18n.locale.toUpperCase() }}</span>
                    <svg class="h-4 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </button>

                <!-- <div v-show="dropdownOpen" -->
                <!-- Dropdown Menu -->
                <div v-show="dropdownOpen" class="absolute mt-1 bg-white border border-gray-200 shadow-lg rounded-md z-10">
                    <span class="text-sm text-gray-800">
                        <span v-for="(locale, index) in $i18n.availableLocales" :key="`locale-${locale}`">
                            <a 
                                href="#" 
                                class="block px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
                                :class="{ 'font-bold text-secondary-tethys': locale === $i18n.locale }"
                                @click.prevent="changeLocale(locale)"
                            >
                                {{ locale.toUpperCase() }}
                            </a>
                        </span>
                    </span>
                </div>
            </div> <!-- Language selection button -->

        </div> <!-- Right section -->

        
    </nav>


    <!-- Mobile Menu (Dropdown) -->
    <div v-if="active" class="md:hidden w-full bg-white shadow-md z-50">
        <span class="flex flex-col text-gray-800 uppercase">
            <li v-for="(route, index) in menuRoutes" :key="index">
                <router-link 
                    class="block px-6 py-3 border hover:bg-gray-100 text-gray-500"
                    :class="{ 'font-bold border-l ': isActive(route.path) }"
                    :to="route.path"
                    @click="active = false"
                >
                    {{ $t(route.label) }}
                </router-link>
            </li>
        </span>

        <span class="flex justify-between items-center px-4 py-3 border-t border-gray-400">
            <!-- About us button -->
            <a v-bind:href="'https://gis.geosphere.at'" target="_self" class="text-gray-800 text-lg">
                <!-- <strong>{{ $t("login") }}</strong> -->
                <i class="fa-solid fa-circle-question"></i>
            </a>

            <!-- Login button -->
            <a v-bind:href="portal" target="_blank" class="text-gray-800 text-sm">
                <strong>{{ $t("login") }}</strong>
            </a>
            
            <!-- Language Selection -->
            <div>
                <button 
                    class="flex py-2 px-2 items-center space-x-2 text-gray-800 hover:bg-gray-100 focus:outline-none" 
                    :title="$t('select_details_language')"
                    @click="dropdownOpen = !dropdownOpen"
                >
                    <span class="text-xs lg:text-sm font-bold">{{ $i18n.locale.toUpperCase() }}</span>
                    <svg class="h-4 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </button>

                <!-- v-show="dropdownOpen" -->
                <!-- Dropdown Menu -->
                <div v-show="dropdownOpen" class="absolute mt-1 ml-2 bg-white border border-gray-200 shadow-lg rounded-md z-10">
                    <span class="text-sm text-gray-800">
                        <span v-for="(locale, index) in $i18n.availableLocales" :key="`locale-${locale}`">
                            <a 
                                href="#" 
                                class="block px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer"
                                :class="{ 'font-bold text-secondary-tethys': locale === $i18n.locale }"
                                @click.prevent="changeLocale(locale)"
                            >
                                {{ locale.toUpperCase() }}
                            </a>
                        </span>
                    </span>
                </div>
            </div> <!-- Language selection button  -->
        </span>

    </div>

    <router-view></router-view>

<footer class="flex flex-wrap h-[900px] text-sm">
    
    <!-- Tethys footer -->
    <div class="w-full flex flex-wrap bg-tethys-6 h-2/3">

        <!-- Logos container -->
        <div class="flex w-4/6 h-1/3 mx-auto border-b-2 border-b-white border-opacity-20 justify-evenly items-center font-bold text-center text-gray-50 text-opacity-40">
            <div class="flex flex-col">
                <span class="mb-4">Certified by</span>
                <img src="./assets/images/CTS_temp.svg" class="h-8 w-12 xl:h-12 xl:w-16 pl-2" />
            </div>
            <div class="flex flex-col items-center">
                <span class="mb-4">Registered at</span>
                <img src="./assets/images/re3data_Logo_RGB_free.png" class="h-8 xl:h-10 pl-2" />
            </div>
            <div class="flex flex-col text-center">
                <span class="mb-4">Listed in</span>
                <img src="./assets/images/BASE_logo_transp.gif" class="h-8 xl:h-10 pl-2" />
            </div>
            <div class="flex flex-col">
                <span class="mb-4">Indexed in</span>
                <img src="./assets/images/Logo_OpenAIRE_temp_white.png" class="h-8 xl:h-10 pl-2" />
            </div>
        </div>

        <!-- Tethys info content -->
        <div class="w-10/12 flex h-2/3 text-white mx-auto justify-evenly items-start">
            <!-- Tethys logo and info -->
            <div class="flex flex-col w-2/5">
                <span></span>
                <img src="./assets/images/tethys_new_logo_temporary_white.png" class="w-60 pt-20 pb-10" alt="">
                <span class="pl-20 pb-2 ml-2">
                    Tethys Research<br>
                    Data Repository
                </span>
                <span class="pl-20 ml-2">
                    Data publisher for<br>
                    Geoscience Austria
                </span>
            </div>
            <!-- Contact block -->
            <div class="flex flex-col w-1/5">
                <div class="uppercase text-lg font-bold pt-32 pb-5">kontakt</div>
                <div class="">
                    <div class="leading-relaxed pb-2">
                        GeoSphere Austria<br>
                        Standort Neulinggasse 38,<br>
                        1030 Wien<br>
                    </div>
                    <i class="fa-solid fa-phone text-base py-1 pr-1"></i> +43 1 7125674<br>
                    <i class="fa-solid fa-envelope text-base py-1 pr-1"></i> repository@geosphere.at
                </div>
            </div>
            <!-- About block -->
            <div class="flex flex-col w-1/5">
                <span class="uppercase text-lg font-bold pt-32 pb-5">about tethys</span>
                <a href="https://gis.geosphere.at/tethys" class="text-gray-50 hover:text-secondary-tethys transition-colors duration-200 py-0.5">News</a>
                <a href="https://gis.geosphere.at/tethys#info" class="text-gray-50 hover:text-secondary-tethys transition-colors duration-200 py-0.5">Info</a>
                <a href="docs/HandbuchTethys.pdf" target="_blank" class="text-gray-50 hover:text-secondary-tethys transition-colors duration-200 py-0.5">Manual</a>
                <a href="docs/PreservationPlanTethys.pdf" target="_blank" class="text-gray-50 hover:text-secondary-tethys transition-colors duration-200 py-0.5" >Preservation Plan</a>
                <a href="" class="text-gray-50 hover:text-secondary-tethys transition-colors duration-200 py-0.5">Imprint</a>
            </div>
            <!-- Tools and support block -->
            <div class="flex flex-col w-1/5">
                <span class="uppercase text-lg font-bold pt-32 pb-5">tools & support</span>
                <a href="https://gis.geosphere.at/tethys#faqs" class="text-md text-gray-50 hover:text-secondary-tethys transition-colors duration-200 py-1">
                    <i class="fa-regular fa-circle-question pr-2 text-base"></i>F&Q
                </a>
                <!-- <a href="" class="text-md text-gray-50 hover:text-secondary-tethys transition-colors duration-200 py-1">
                    <i class="fa-solid fa-circle-nodes pr-2 text-base">OAI</i>
                </a> -->
                <li class="list-none py-1" >
                    <!-- <i class="fa-solid fa-link pr-1 text-sm"></i> Sitelinks -->
                    <router-link class="text-gray-50 hover:text-secondary-tethys transition-colors duration-200 py-1" to="/oai">
                        <i class="fa-solid fa-circle-nodes pr-1 text-base"></i> OAI
                    </router-link>
                </li>
                <!-- <a href="" class="text-md text-gray-50 hover:text-secondary-tethys transition-colors duration-200 py-1"></a> -->
                <li class="list-none py-1" >
                    <!-- <i class="fa-solid fa-link pr-1 text-sm"></i> Sitelinks -->
                    <router-link class="text-gray-50 hover:text-secondary-tethys transition-colors duration-200 py-1" to="/sitelinks">
                        <i class="fa-solid fa-link pr-1 text-sm"></i> Sitelinks
                    </router-link>
                </li>
                <!-- </a> -->
                <!-- <a href="" class="text-gray-50 hover:text-secondary-tethys transition-colors duration-200 py-1"> -->
                <li class="list-none py-1">
                    <!-- <i class="px-1 fa-solid fa-file-lines text-base"></i> Terms & Conditions -->
                    <router-link class="text-gray-50 hover:text-secondary-tethys transition-colors duration-200 py-1" to="/terms-and-conditions">
                        <i class="fa-solid fa-file-lines px-1 text-base"></i> Terms & Conditions
                    </router-link>
                </li>
                <!-- </a> -->
            </div>
        </div>

    </div>

    <!-- GeoSphere footer -->
    <div class="w-full flex flex-wrap bg-geosphere-midnight-green h-1/3">
        
        <div class="w-10/12 flex text-white mx-auto justify-evenly items-start">
            <!-- GeoSphere logo and info -->
            <div class="flex flex-col w-2/5">
                <span></span>
                <img src="./assets/site/img/GSA_LogoMitZusatz_NegativAufMidnightGreen_RGB_XXS.svg" class="w-56 pt-10 pb-10" alt="">
            </div>
            <!-- Contact block -->
            <div class="flex flex-col w-1/5 ">
                <a :href="`https://www.geosphere.at/${$i18n.locale.toLowerCase()}`" class="text-base font-bold pt-24 pb-2 text-gray-50 hover:text-secondary-tethys transition-colors duration-200">
                    <i class="fa-solid fa-house pr-1"></i> www.geosphere.at
                </a>
                <!-- <div class="text-base font-bold pt-24 pb-2">www.geosphere.at</div> -->
                <div class="">
                    <div class="leading-relaxed pb-2">
                        GeoSphere Austria<br>
                        Hohe Warte 38,<br>
                        1190 Wien<br>
                    </div>
                </div>

                <!-- Bottom Legal Links  -->
                <div class="w-full text-center text-gray-400 text-sm pt-10">
                    <a :href="`https://www.geosphere.at/de/impressum`" class="pr-5 text-gray-50 hover:text-secondary-tethys transition-colors duration-200 border-r-2 border-r-geosphere-lemon">Impressum</a>
                    <a :href="`https://www.geosphere.at/de/datenschutz`" class="px-5 text-gray-50 hover:text-secondary-tethys transition-colors duration-200 border-r-2 border-r-geosphere-lemon">Datenschutz</a>
                    <a :href="`https://www.geosphere.at/de/legal`" class="px-5 text-gray-50 hover:text-secondary-tethys transition-colors duration-200 border-r-2 border-r-geosphere-lemon">Legal</a>
                    <a :href="`https://www.geosphere.at/de/barrierefreiheit`" class="px-5 text-gray-50 hover:text-secondary-tethys transition-colors duration-200">Barrierefreiheit</a>
                </div>

            </div>

            
            <!-- Social media links -->
            <div class="flex flex-col w-2/5">
                <!-- Links container -->
                <div class="w-full flex justify-center pt-24 space-x-1">
                    
                    <!-- First Group (Instagram & X) -->
                    <div class="w-1/3 flex flex-col px-1">
                        <div class="py-2">
                            <a target="_blank" href="https://www.instagram.com/GeoSphere_AT" rel="noreferrer" class="flex items-center space-x-2 text-gray-50 hover:text-secondary-tethys transition-colors duration-200">
                                <img src="./assets/images/instagram.svg" class="h-8 w-8">
                                <span>Instagram</span>
                            </a>
                        </div>
                        <div class="py-2">
                            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/GeoSphere.at" class="flex items-center space-x-2 text-gray-50 hover:text-secondary-tethys transition-colors duration-200">
                                <img src="./assets/images/facebook.svg" class="h-8 w-8"> 
                                <span>Facebook</span>
                            </a>
                        </div>
                    </div>

                    <!-- Second Group (YouTube & Facebook) -->
                    <div class="w-1/3 flex flex-col px-2">
                        <div class="py-2">
                            <a target="_blank" rel="noreferrer" href="https://x.com/GeoSphere_AT" class="flex items-center space-x-2 text-gray-50 hover:text-secondary-tethys transition-colors duration-200">
                                <img src="./assets/images/x-twitter.svg" class="h-8 w-8">
                                <span>X</span>
                            </a>
                        </div>
                        <div class="py-2">
                            <a target="_blank" rel="noreferrer" href="https://at.linkedin.com/company/geosphereaustria" class="flex items-center space-x-2 text-gray-50 hover:text-secondary-tethys transition-colors duration-200">
                                <img src="./assets/images/linkedin.svg" class="h-8 w-8">
                                <span>LinkedIn</span>
                            </a>
                        </div>
                    </div>

                    <!-- Third Group (LinkedIn & Flickr) -->
                    <div class="w-1/3 flex flex-col px-2">
                        <div class="py-2">
                            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/@geosphereaustria2023" class="flex items-center space-x-2 text-gray-50 hover:text-secondary-tethys transition-colors duration-200">
                                <img src="./assets/images/youtube.svg" class="h-8 w-8">
                                <span>YouTube</span>
                            </a>
                        </div>
                        <div class="py-2">
                            <a target="_blank" rel="noreferrer" href="https://www.flickr.com/photos/geosphere_austria" class="flex items-center space-x-2 text-gray-50 hover:text-secondary-tethys transition-colors duration-200">
                                <img src="./assets/images/flickr.svg" class="h-8 w-8">
                                <span>Flickr</span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>



        </div>

        <!-- Bottom Legal Links  -->
        <!-- <div class="w-full text-center text-gray-400 text-sm">
            <a :href="`https://www.geosphere.at/de/impressum`" class="px-5 text-gray-50 hover:text-secondary-tethys transition-colors duration-200 border-r-2 border-r-geosphere-lemon">Impressum</a>
            <a :href="`https://www.geosphere.at/de/datenschutz`" class="px-5 text-gray-50 hover:text-secondary-tethys transition-colors duration-200 border-r-2 border-r-geosphere-lemon">Datenschutz</a>
            <a :href="`https://www.geosphere.at/de/legal`" class="px-5 text-gray-50 hover:text-secondary-tethys transition-colors duration-200 border-r-2 border-r-geosphere-lemon">Legal</a>
            <a :href="`https://www.geosphere.at/de/barrierefreiheit`" class="px-5 text-gray-50 hover:text-secondary-tethys transition-colors duration-200">Barrierefreiheit</a>
        </div> -->

    </div>

</footer>


    <!-- <footer class="container-fluid">
        <div class="columns">
            <div class="column col-sm">
                <div class="card mx-auto" style="width: 18rem">
                    <div class="card-body">
                        <h5 class="card-title">About TETHYS</h5>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item"><router-link to="/oai">OAI</router-link></li>
                            <li class="list-group-item"><a href="https://www.geosphere.at/" target="_blank">geosphere.at</a></li>
                            <li class="list-group-item"><a href="https://www.geologie.ac.at/news/news" target="_blank">News</a></li>
                            <li class="list-group-item"><a href="docs/PreservationPlanTethys.pdf" target="_blank">Preservation Plan</a></li>
                            <li class="list-group-item"><a href="docs/HandbuchTethys.pdf" target="_blank">Manual</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="column col-sm">
                <div class="card mx-auto" style="width: 18rem">
                    <div class="card-body">
                        <h5 class="card-title">Tools and Support</h5>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <router-link to="/contact"><i class="far fa-id-card"></i> Contact</router-link>
                            </li>
                            <li class="list-group-item">
                                <router-link to="/imprint"><i class="fas fa-stamp"></i> Imprint</router-link>
                            </li>
                            <li class="list-group-item">
                                <router-link to="/sitelinks"><i class="fas fa-link"></i> Sitelinks</router-link>
                            </li>
                            <li class="list-group-item">
                                <router-link to="/terms-and-conditions"><i class="far fa-file-alt"></i> Terms &amp; Conditions</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="column col-sm">
                <div class="card mx-auto" style="width: 18rem">
                    <div class="card-body">
                        <h5 class="card-title">Connect with us</h5>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <a target="_blank" href="https://www.geosphere.at/"><i class="fas fa-home pr-2"></i>geosphere.at</a>
                            </li>
                            <li class="list-group-item">
                                <a href="#"><i class="fas fa-phone-alt pr-2"></i> +43-1-7125674</a>
                            </li>
                            <li class="list-group-item">
                                <a href="mailto:repository@geologie.ac.at"><i class="fas fa-envelope pr-2"></i> repository(at)geosphere.at </a>
                            </li>
                        </ul>
                        <img class="img-fluid pt-4" src="@/assets/site/img/geosphere-austria-logo-negativ.png" alt="GeoSphere Austria Logo" />
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <div class="container-fluid">
        <div class="copyright-notice">&#169; {{ currentYear }} TETHYS RDR</div>
    </div> -->


</template>

<script lang="ts">
import App from "./app";
export default App;
</script>

<style scoped lang="scss">
/* Smooth dropdown animation */
nav div {
    transition: all 0.3s ease-in-out;
}


@import 'leaflet/dist/leaflet.css';
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
//   margin-top: 60px;
//   width: 100%;
//   height: 100%;
// }
#app {
    p,
    ol,
    ul,
    // li,
    dl,
    textarea,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 1.5rem;
    }
}

// footer:
// footer {
//     background-image: url(./assets/site/img/footer-bg.jpg);

//     background-size: cover;
//     background-position: center center;
//     background-repeat: no-repeat;
//     padding-top: 26em;
//     padding-bottom: 3.8em;
// }

// footer .card {
//     background: transparent;
//     border: none;
//     position: relative;
//     margin-top: 2em;
//     box-shadow: none;
// }

// footer .card-title {
//     // font-family: Verdana;
//     color: #fff;
//     text-transform: uppercase;
//     font-weight: bold;
// }

.list-group-flush {
    border-radius: 0;
}
.list-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
}
.list-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
.list-group-flush > .list-group-item {
    border-width: 0;
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}
.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}
// footer .list-group-item a {
//     color: #fff;
//     text-decoration: none;
// }

.copyright-notice {
    text-align: center;
    color: #fff;
    background-color: #384d6e;
    padding: 0.3em 0;
}

.development-notice {
    text-align: center;
    color: #fff;
    background-color: red;
    padding: 1em 0;
    font-weight: bold;
}
</style>
