<template>
    <!-- Loop through each document in results and create a key for each item -->
    <div v-for="document in results" :key="document.id" class="flex items-start space-x-2 pb-4">

        <div class="flex rounded-md shadow-lg bg-white">

            <!-- Container for minimap at the left -->
            <div class="hidden lg:block w-1/5">
                <!-- Pass bounds from document to Minimap -->
                <ResultsMinimap :bounds="getBounds(document)" :mapId="document.id" class="z-10" ></ResultsMinimap>
            </div>

            <!-- Container with border and padding for each document -->
            <div class="lg:w-4/5 2xl:w-5/6 lg:pl-4 py-4">
                <!-- Spacing between elements within the container -->
                <div>
                    <!-- Title section with a link to the dataset page -->
                    <h4>
                        <router-link
                            class="text-tethys-5 line-clamp-3 lg:line-clamp-1 pb-1.5 font-bold hover:underline no-underline cursor-pointer text-md"
                            :to="{ name: 'dataset', params: { datasetId: document.id } }"
                        >
                            <!-- {{ document.title_output }} -->
                            <span v-if="document.language.toUpperCase() == $i18n.locale">
                                <p>{{ document.title }}</p>
                            </span>
                            <span v-else>
                                <p v-if="document.title_additional[0]">{{ document.title_additional[0] }}</p>
                                <p v-else>{{ document.title }}</p>
                            </span>
                        </router-link>
                        <!-- <p>{{ document.title }}</p>
                        <p class="text-red-500">{{ document.title_additional[0] }}</p>
                        <p class="text-blue-500">{{ document.title_additional[1] }}</p> -->

                    </h4>
                    <!-- DOI section; displayed only if an identifier exists -->
                    <span v-if="document.identifier && document.identifier.length > 0" class="">
                        <!-- DOI label -->
                        <span class="inline-block px-2 py-1.5 mt-1 text-xs font-bold text-gray-500 bg-gray-200 rounded shadow-sm" :title="$t('data_type')">
                            DOI
                        </span>
                        <a
                            target="_blank"
                            :href="'https://doi.org/' + document.identifier[0]"
                            class="text-tethys-4 text-sm pl-2"
                        >
                            {{ "https://doi.org/" + document.identifier[0] }}
                            <span class="px-2 text-xs">&#10148;</span>
                        </a>
                        
                        <!-- Display author information conditionally -->
                        <span v-if="document.author && document.author.length > 0" class="text-gray-500 pointer-events-none text-sm">
                            <!-- Single author -->
                            <span v-if="document.author.length === 1" >
                                {{ simplifyAuthor(document.author[0]) }}
                            </span>
                            <!-- Multiple authors (2-3), separated by semicolons -->
                            <span v-if="document.author.length > 1 && document.author.length < 3">
                                <span
                                    v-for="(author, index) in document.author"
                                    :key="index"
                                >
                                    {{ simplifyAuthor(author) }}
                                    <span v-if="index < document.author.length - 1">; </span>
                                </span>
                            </span>
                            <!-- More than three authors; first two followed by "et al." -->
                            <span v-if="document.author.length >= 3" >
                                <span
                                    v-for="(author, index) in document.author.slice(0, 2)"
                                    :key="index"
                                >
                                    {{ simplifyAuthor(author) }}
                                    <span v-if="index < 1">; </span>
                                </span>
                                et al.
                            </span>
                        </span>
                    </span>
                    <!-- Abstract section -->
                    <p class="pt-2 pr-3 line-clamp-3 sm:line-clamp-2 relative overflow-hidden">
                        <!-- <p class="pt-2  relative overflow-hidden"> -->
                        <!-- Abstract text with clamping and ellipsis -->
                        <span class="font-light text-sm">
                            <!-- {{ document.abstract[0] }} -->
                            <span v-if="document.language.toUpperCase() === $i18n.locale">
                                {{ document.abstract[0] }}
                            </span>
                            <span v-else>
                                <span v-if="document.abstract[1]">{{ document.abstract[1] }}</span>
                                <span v-else>{{ document.abstract[0] }}</span>
                            </span>
                            <!-- <span class="absolute bottom-0 right-0 bg-white">...</span> -->
                        </span>
                    </p>
                            <!-- Section for dataset ABSTRACTS -->
                            <!-- <div v-if="dataset.hasOwnProperty('abstracts')" class="flex flex-wrap pb-10">
                                <div class="w-full">
                                    <span v-if="dataset.MainAbstract?.language.toUpperCase() == $i18n.locale">
                                        <p>{{ dataset.MainAbstract?.value }}</p>
                                    </span>
                                    <span v-else>
                                        <p v-if="dataset.hasTranslatedAbstract()">{{ dataset.TranslatedAbstract?.value }}</p>
                                        <p v-else>{{ dataset.MainAbstract?.value }}</p>
                                    </span>
                                </div>
                            </div> -->

                    <!-- Publication Date, Document type and open access information -->
                    <p class="pt-4">
                        <!-- Publication date formatted by a conversion function -->
                        <span class="inline-block pl-2 pr-3 py-1 text-[8pt] font-normal text-gray-500 bg-white">
                            <i class="fa-regular fa-calendar text-xs text-tethys-4"></i> {{ $t('published') + ": " + convert(document.server_date_published, $i18n.locale) }}
                        </span>
                        <!-- Container for document type and open access labels -->
                        <span class="block sm:inline-block mt-2 sm:mt-0">
                            <!-- Document type label -->
                            <span class="inline-block px-3 py-1 text-xs font-normal text-tethys-5 italic bg-tethys-3 rounded-xl shadow-sm" :title="$t('data_type')">
                                <i class="fas fa-file pr-1"></i> {{ $t(document.doctype) }}
                            </span>
                            <!-- Open access label, displayed only for specific licenses -->
                            <span
                                v-if="openAccessLicences.includes(document.licence)"
                                class="inline-block px-2.5 py-1 text-xs font-normal text-tethys-5 bg-tethys-3 rounded-xl shadow-sm ml-2 sm:ml-0"
                                :title="$t('license_open')"
                            >
                                <i class="fas fa-lock-open"></i>
                            </span>
                            <!-- Embargo label if embargo has not passed -->
                            <!-- TODO: Implement final general processing!! -->
                            <span v-if="document.id == 236"
                                class="inline-block px-3 py-1 text-xs font-normal text-tethys-5 bg-tethys-3 rounded-xl shadow-sm ml-2 sm:ml-0"
                                :title="$t('dataset_under_embargo_icon')"
                            >
                                <i class="fa-solid fa-hourglass-half"></i>
                            </span>
                        </span>
                    </p>
                </div>
            </div>






        </div>

    </div>
</template>

<script lang="ts">
import VsResults from "./vs-result";
export default VsResults;
</script>

<style lang="scss" scoped>

// OLD STYLING WITH BULMA =============================================================================

// .card {
//     border-radius: 0;
//     /* rempve box-shadow */
//     box-shadow: none;
// }

// /* overflow for abstracts */
// .clamped {
//     line-height: 1.5;
//     overflow: hidden;
//     position: relative;
// }
// .clamped-2 {
//     /* Clamp to 2 lines, ie line-height x 2: */
//     max-height: 4.5em;
// }
// .ellipsis {
//     background: #fff;
//     bottom: 0;
//     position: absolute;
//     right: 0;
// }
// .fill {
//     background: #fff;
//     height: 100%;
//     position: absolute;
//     width: 100%;
// }

// /* zenodo */
// .label-success {
//     background-color: #5cb85c;
// }
// .label-default {
//     background-color: #777;
// }
// .label-info {
//     background-color: #6aa3d5;
// }
// .label-keyword {
//     background-color: #7e7e7e;
// }
// .label {
//     display: inline-block;
//     padding: 0.2em 0.12em 0.3em;
//     font-size: 75%;
//     color: #fff;
//     border-radius: 0.25em;
//     margin-right: 1.25em;
//     text-transform: uppercase;
//     font-weight: normal;
// }
// /* .record-elem .h4, record-elem h4 {
//     font-size: 18px;
// } */

// .record-elem p a {
//     color: #33cccc;
//     font-size: 14px;
// }
// /* .record-elem h4 a {
//     color: #000;
// } */

// .record-elem h4 a {
//     cursor: pointer;
//     color: #000;
//     text-decoration: none;
//     font-weight: bold;
//     /* margin-left: 10px; */
//     // font-size: 16px;
// }
// .record-elem h4 a:hover {
//     color: #777;
//     text-decoration: none;
// }
// /* .search-detail a:hover {
//     color: rgb(0, 128, 0);
// } */
// .record-elem .h4,
// .record-elem h4 {
//     // font-size: 16px;
//     letter-spacing: 0.05em;
//     // margin-top: 8px;
// }
// .record-detail h1,
// .record-detail p,
// .record-elem h4,
// .record-elem p,
// .well {
//     word-wrap: break-word;
// }
// .record-elem p span {
//     color: #000;
//     font-size: 14px;
// }
// .record-elem p span.disabled {
//     color: #7e7e7e;
//     // color: lightgray;
//     pointer-events: none;
// }
// .record-elem p span i {
//     color: #336699;
// }

// .post {
//     position: relative;
//     min-height: 1px;
//     padding-left: 15px;
//     padding-right: 15px;

//     box-sizing: border-box;
//     margin-bottom: 10px;
//     margin-top: 15px;
// }
/* .record-elem {
    border-top: 1px solid rgb(238, 238, 238);
} */
</style>
