import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex rounded-md shadow-lg bg-white" }
const _hoisted_2 = { class: "hidden lg:block w-1/5" }
const _hoisted_3 = { class: "lg:w-4/5 2xl:w-5/6 lg:pl-4 py-4" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: ""
}
const _hoisted_9 = ["title"]
const _hoisted_10 = ["href"]
const _hoisted_11 = {
  key: 0,
  class: "text-gray-500 pointer-events-none text-sm"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "pt-2 pr-3 line-clamp-3 sm:line-clamp-2 relative overflow-hidden" }
const _hoisted_18 = { class: "font-light text-sm" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "pt-4" }
const _hoisted_24 = { class: "inline-block pl-2 pr-3 py-1 text-[8pt] font-normal text-gray-500 bg-white" }
const _hoisted_25 = { class: "block sm:inline-block mt-2 sm:mt-0" }
const _hoisted_26 = ["title"]
const _hoisted_27 = ["title"]
const _hoisted_28 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResultsMinimap = _resolveComponent("ResultsMinimap")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (document) => {
    return (_openBlock(), _createElementBlock("div", {
      key: document.id,
      class: "flex items-start space-x-2 pb-4"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ResultsMinimap, {
            bounds: _ctx.getBounds(document),
            mapId: document.id,
            class: "z-10"
          }, null, 8, ["bounds", "mapId"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createElementVNode("h4", null, [
              _createVNode(_component_router_link, {
                class: "text-tethys-5 line-clamp-3 lg:line-clamp-1 pb-1.5 font-bold hover:underline no-underline cursor-pointer text-md",
                to: { name: 'dataset', params: { datasetId: document.id } }
              }, {
                default: _withCtx(() => [
                  (document.language.toUpperCase() == _ctx.$i18n.locale)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                        _createElementVNode("p", null, _toDisplayString(document.title), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                        (document.title_additional[0])
                          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(document.title_additional[0]), 1))
                          : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(document.title), 1))
                      ]))
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            (document.identifier && document.identifier.length > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                  _createElementVNode("span", {
                    class: "inline-block px-2 py-1.5 mt-1 text-xs font-bold text-gray-500 bg-gray-200 rounded shadow-sm",
                    title: _ctx.$t('data_type')
                  }, " DOI ", 8, _hoisted_9),
                  _createElementVNode("a", {
                    target: "_blank",
                    href: 'https://doi.org/' + document.identifier[0],
                    class: "text-tethys-4 text-sm pl-2"
                  }, [
                    _createTextVNode(_toDisplayString("https://doi.org/" + document.identifier[0]) + " ", 1),
                    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "px-2 text-xs" }, "➤", -1))
                  ], 8, _hoisted_10),
                  (document.author && document.author.length > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                        (document.author.length === 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.simplifyAuthor(document.author[0])), 1))
                          : _createCommentVNode("", true),
                        (document.author.length > 1 && document.author.length < 3)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(document.author, (author, index) => {
                                return (_openBlock(), _createElementBlock("span", { key: index }, [
                                  _createTextVNode(_toDisplayString(_ctx.simplifyAuthor(author)) + " ", 1),
                                  (index < document.author.length - 1)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_14, "; "))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true),
                        (document.author.length >= 3)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(document.author.slice(0, 2), (author, index) => {
                                return (_openBlock(), _createElementBlock("span", { key: index }, [
                                  _createTextVNode(_toDisplayString(_ctx.simplifyAuthor(author)) + " ", 1),
                                  (index < 1)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, "; "))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128)),
                              _cache[1] || (_cache[1] = _createTextVNode(" et al. "))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_17, [
              _createElementVNode("span", _hoisted_18, [
                (document.language.toUpperCase() === _ctx.$i18n.locale)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(document.abstract[0]), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_20, [
                      (document.abstract[1])
                        ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(document.abstract[1]), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(document.abstract[0]), 1))
                    ]))
              ])
            ]),
            _createElementVNode("p", _hoisted_23, [
              _createElementVNode("span", _hoisted_24, [
                _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa-regular fa-calendar text-xs text-tethys-4" }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('published') + ": " + _ctx.convert(document.server_date_published, _ctx.$i18n.locale)), 1)
              ]),
              _createElementVNode("span", _hoisted_25, [
                _createElementVNode("span", {
                  class: "inline-block px-3 py-1 text-xs font-normal text-tethys-5 italic bg-tethys-3 rounded-xl shadow-sm",
                  title: _ctx.$t('data_type')
                }, [
                  _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-file pr-1" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t(document.doctype)), 1)
                ], 8, _hoisted_26),
                (_ctx.openAccessLicences.includes(document.licence))
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "inline-block px-2.5 py-1 text-xs font-normal text-tethys-5 bg-tethys-3 rounded-xl shadow-sm ml-2 sm:ml-0",
                      title: _ctx.$t('license_open')
                    }, _cache[4] || (_cache[4] = [
                      _createElementVNode("i", { class: "fas fa-lock-open" }, null, -1)
                    ]), 8, _hoisted_27))
                  : _createCommentVNode("", true),
                (document.id == 236)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: "inline-block px-3 py-1 text-xs font-normal text-tethys-5 bg-tethys-3 rounded-xl shadow-sm ml-2 sm:ml-0",
                      title: _ctx.$t('dataset_under_embargo_icon')
                    }, _cache[5] || (_cache[5] = [
                      _createElementVNode("i", { class: "fa-solid fa-hourglass-half" }, null, -1)
                    ]), 8, _hoisted_28))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ])
    ]))
  }), 128))
}