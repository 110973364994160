import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './assets/images/TETHYS-Logo.svg'
import _imports_1 from './assets/images/cts-logo.png'


const _hoisted_1 = {
  class: "navbar navbar-light border-bottom",
  role: "navigation",
  "aria-label": "main navigation"
}
const _hoisted_2 = { class: "navbar-brand" }
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  class: "navbar-start",
  style: {"flex-grow":"1","justify-content":"center"}
}
const _hoisted_5 = { class: "navbar-item" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "navbar-item" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "navbar-item" }
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "navbar-item" }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "navbar-item" }
const _hoisted_14 = ["href"]
const _hoisted_15 = { class: "navbar-end" }
const _hoisted_16 = { class: "navbar-item" }
const _hoisted_17 = { class: "buttons" }
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "container-fluid pt-32" }
const _hoisted_20 = { class: "copyright-notice" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", {
          class: "navbar-item",
          href: _ctx.app_url
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            src: _imports_0,
            class: "w-52 sm:w-60 sm:h-[86px]"
          }, null, -1),
          _createTextVNode("    "),
          _createElementVNode("a", {
            href: "https://doi.org/10.34894/TKWVFL",
            target: "_blank"
          }, [
            _createElementVNode("img", {
              src: _imports_1,
              class: "w-12 h-12 sm:w-20 sm:h-20"
            })
          ], -1)
        ]), 8, _hoisted_3),
        _createElementVNode("a", {
          id: "menu-icon",
          role: "button",
          class: _normalizeClass(["navbar-burger", _ctx.active ? 'is-active' : '']),
          "aria-label": "menu",
          "aria-expanded": "false",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showMobilemenu && _ctx.showMobilemenu(...args)))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
          _createElementVNode("span", { "aria-hidden": "true" }, null, -1)
        ]), 2)
      ]),
      _createElementVNode("div", {
        id: "navMenu",
        class: _normalizeClass(["navbar-menu", _ctx.active ? 'is-active' : ''])
      }, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createElementVNode("a", {
              class: "navbar-link is-arrowless active",
              href: _ctx.app_url
            }, "STARTSEITE", 8, _hoisted_6)
          ]),
          _createElementVNode("li", _hoisted_7, [
            _createElementVNode("a", {
              class: "navbar-link is-arrowless",
              href: _ctx.search_url
            }, "SEARCH", 8, _hoisted_8)
          ]),
          _createElementVNode("li", _hoisted_9, [
            _createElementVNode("a", {
              class: "navbar-link is-arrowless",
              href: _ctx.service_url
            }, "SERVICES", 8, _hoisted_10)
          ]),
          _createElementVNode("li", _hoisted_11, [
            _createElementVNode("a", {
              class: "navbar-link is-arrowless",
              href: _ctx.help_url
            }, "HELP", 8, _hoisted_12)
          ]),
          _createElementVNode("li", _hoisted_13, [
            _createElementVNode("a", {
              class: "navbar-link is-arrowless",
              href: _ctx.oai_url
            }, "OAI", 8, _hoisted_14)
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("a", {
                href: _ctx.portal,
                target: "_blank",
                class: "button is-primary custom-button"
              }, [
                _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-sign-in-alt" }, null, -1)),
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t("login")), 1)
              ], 8, _hoisted_18)
            ])
          ])
        ])
      ], 2)
    ]),
    _createVNode(_component_router_view),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, "© " + _toDisplayString(_ctx.currentYear) + " TETHYS RDR", 1)
    ])
  ], 64))
}