<template>
    <div class="flex flex-col items-center w-full h-full bg-tethys-1 overflow-hidden" autocomplete="off">
        <!-- Search input section -->
        <!-- <div class="search-banner w-full bg-cover bg-no-repeat py-24 min-h-[400px] bg-[position:50%_25%]"> -->
        <!-- <div class="search-banner w-full bg-cover bg-no-repeat min-h-[412px] bg-[position:50%_50%]"> -->
        <div
            class="search-banner w-full bg-cover bg-no-repeat min-h-64 lg:min-h-[350px] bg-[position:50%_50%] relative before:absolute before:top-0 before:left-0 before:w-full before:h-4 before:bg-gradient-to-b before:from-black before:to-transparent before:opacity-20 after:absolute after:bottom-0 after:left-0 after:w-full after:h-4 after:bg-gradient-to-t after:from-black after:to-transparent after:opacity-20">
            <vs-input v-bind:placeholder="$t('enter_your_search_term')" @search-change="onSearch"></vs-input>
        </div>

        <section class="w-full px-4 pt-16 pb-32 bg-white text-center">
            <div class="w-10/12 xl:w-5/6 2xl:w-4/6 mx-auto">
                <!-- Title -->
                <h2 class="text-2xl md:text-3xl font-bold text-tethys-5 mb-4">
                    {{ $t('home_title') }}
                </h2>

                <!-- Subtitle -->
                <p class="text-gray-500 text-sm md:text-base max-w-4xl mx-auto mb-10 leading-relaxed">
                    {{ $t('home_subtitle') }}
                </p>

                <!-- Feature grid -->
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">

                    <!-- Card: Safe -->
                    <div
                        class="group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg">
                        <div
                            class="w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl">
                            <i class="fas fa-shield-alt"></i>
                        </div>
                        <h3 class="font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1">
                            {{ $t('cards.safe.title') }}
                        </h3>
                        <p class="text-sm text-gray-500 transition-all duration-300">
                            <span class="inline group-hover:hidden">{{ $t('cards.safe.description_default') }}</span>
                            <span class="hidden group-hover:inline">{{ $t('cards.safe.description_hover') }}</span>
                        </p>
                    </div>

                    <!-- Card: Comprehensible -->
                    <div
                        class="group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg">
                        <div
                            class="w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl">
                            <i class="fas fa-file-alt"></i>
                        </div>
                        <h3 class="font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1">
                            {{ $t('cards.comprehensible.title') }}
                        </h3>
                        <p class="text-sm text-gray-500 transition-all duration-300">
                            <span class="inline group-hover:hidden">{{ $t('cards.comprehensible.description_default')
                                }}</span>
                            <span class="hidden group-hover:inline">{{ $t('cards.comprehensible.description_hover')
                                }}</span>
                        </p>
                    </div>

                    <!-- Card: Versioning -->
                    <div
                        class="group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg">
                        <div
                            class="w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl">
                            <i class="fas fa-code-branch"></i>
                        </div>
                        <h3 class="font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1">
                            {{ $t('cards.versioning.title') }}
                        </h3>
                        <p class="text-sm text-gray-500 transition-all duration-300">
                            <span class="inline group-hover:hidden">{{ $t('cards.versioning.description_default')
                                }}</span>
                            <span class="hidden group-hover:inline">{{ $t('cards.versioning.description_hover')
                                }}</span>
                        </p>
                    </div>

                    <!-- Card: FAIR Principles -->
                    <div
                        class="group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg">
                        <div
                            class="w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl">
                            <i class="fas fa-database"></i>
                        </div>
                        <h3 class="font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1">
                            {{ $t('cards.fair_principles.title') }}
                        </h3>
                        <p class="text-sm text-gray-500 transition-all duration-300">
                            <span class="inline group-hover:hidden">{{ $t('cards.fair_principles.description_default')
                                }}</span>
                            <span class="hidden group-hover:inline">{{ $t('cards.fair_principles.description_hover')
                                }}</span>
                        </p>
                    </div>

                    <!-- Card: Certified -->
                    <div
                        class="group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg">
                        <div
                            class="w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl">
                            <i class="fas fa-check-circle"></i>
                        </div>
                        <h3 class="font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1">
                            {{ $t('cards.certified.title') }}
                        </h3>
                        <p class="text-sm text-gray-500 transition-all duration-300">
                            <span class="inline group-hover:hidden">{{ $t('cards.certified.description_default')
                                }}</span>
                            <span class="hidden group-hover:inline">{{ $t('cards.certified.description_hover') }}</span>
                        </p>
                    </div>

                    <!-- Card: Citable -->
                    <div
                        class="group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg">
                        <div
                            class="w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl">
                            <i class="fas fa-quote-right"></i>
                        </div>
                        <h3 class="font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1">
                            {{ $t('cards.citable.title') }}
                        </h3>
                        <p class="text-sm text-gray-500 transition-all duration-300">
                            <span class="inline group-hover:hidden">{{ $t('cards.citable.description_default') }}</span>
                            <span class="hidden group-hover:inline">{{ $t('cards.citable.description_hover') }}</span>
                        </p>
                    </div>

                    <!-- Card: Open Access -->
                    <div
                        class="group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg">
                        <div
                            class="w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl">
                            <i class="fas fa-unlock"></i>
                        </div>
                        <h3 class="font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1">
                            {{ $t('cards.open_access.title') }}
                        </h3>
                        <p class="text-sm text-gray-500 transition-all duration-300">
                            <span class="inline group-hover:hidden">{{ $t('cards.open_access.description_default')
                                }}</span>
                            <span class="hidden group-hover:inline">{{ $t('cards.open_access.description_hover')
                                }}</span>
                        </p>
                    </div>

                    <!-- Card: Review Process -->
                    <div
                        class="group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg">
                        <div
                            class="w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl">
                            <i class="fas fa-clipboard-check"></i>
                        </div>
                        <h3 class="font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1">
                            {{ $t('cards.review_process.title') }}
                        </h3>
                        <p class="text-sm text-gray-500 transition-all duration-300">
                            <span class="inline group-hover:hidden">{{ $t('cards.review_process.description_default')
                                }}</span>
                            <span class="hidden group-hover:inline">{{ $t('cards.review_process.description_hover')
                                }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import HomeViewComponent from "./home-view-component";
export default HomeViewComponent;
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "./home-view-component.scss";

.search-banner {
    background-image: url("/src/assets/site/img/main-search-banner.jpg");
    /* box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.2), inset 0 -5px 5px rgba(0, 0, 0, 0.2); */
}
</style>
