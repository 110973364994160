<!-- detail-dataset.component.vue -->
<template v-if="datasetId">
    <div class="flex flex-col items-center w-full h-full bg-tethys-1 overflow-hidden" autocomplete="off">
       
        <!-- Search input section -->
        <div class="search-banner w-full bg-cover bg-no-repeat min-h-64 lg:min-h-[350px] bg-[position:50%_50%] relative before:absolute before:top-0 before:left-0 before:w-full before:h-4 before:bg-gradient-to-b before:from-black 
        before:to-transparent before:opacity-20 after:absolute after:bottom-0 after:left-0 after:w-full after:h-4 after:bg-gradient-to-t after:from-black after:to-transparent after:opacity-20">
            <vs-input v-bind:propDisplay="searchTerm" v-bind:placeholder="$t('enter_your_search_term')" @search-change="onSearch"></vs-input>
        </div>

        
        <!-- Section that shows the dataset details once the data is loaded -->
        <section v-if="loaded" class="py-5">

            <!-- <div class="w-11/12 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto"> -->
            <div class="w-10/12 xl:w-5/6 2xl:w-4/6 mx-auto">
                <!-- md:flex-row w-10/12 xl:w-5/6 2xl:w-4/6 -->

                <div class="flex flex-wrap">

                    <!-- Left content block displaying dataset main attributes -------------------------------------------------------------------------------- --> 
                    <div class="w-full lg:w-8/12">

                        <!-- First line of labels with publication date, document type and open access information -->
                        <div class="flex justify-between items-center">
                            <!-- Publication date box located at the left -->
                            <span 
                                class="px-2 py-2 text-sm  text-gray-400 uppercase"
                            >
                                {{ $t('published') + ": " + getSimpleDate(dataset.server_date_published) }}
                            </span>
                        </div>

                        <!-- Container for title, DOI, abstract, files -->
                        <!-- <div class="bg-white border shadow rounded p-4 mb-5"> -->
                        <div class="bg-white border shadow-xl rounded-xl">

                            <!-- Container for document type and open access labels -->
                            <span class="flex justify-end items-center pt-3 pb-2 pr-4">
                                <!-- Document type label -->
                                <span class="inline-block px-3 py-1 text-xs font-normal text-tethys-5 italic bg-tethys-3 rounded-xl shadow-sm" :title="$t('data_type')">
                                    <i class="fas fa-file pr-1"></i> {{ $t(dataset.type) }}
                                </span>

                                <!-- Open Access label if applicable -->
                                <span v-if="dataset.hasLicenses()">
                                    <span v-for="license in dataset.licenses" :key="license.id">
                                        <span
                                            v-if="openAccessLicences.includes(license.name)"
                                            class="inline-block px-3 mb-0.5 py-1 text-xs font-normal text-tethys-5 bg-tethys-3 rounded-xl shadow-sm ml-2 sm:ml-0"
                                            :title="$t('license_open')"
                                        >
                                            <i class="fas fa-lock-open"></i>
                                        </span>
                                    </span>
                                </span>

                                <!-- Embargo label if embargo has not passed -->
                                <!-- TODO: Implement final general processing!! -->
                                <span v-if="!dataset.hasEmbargoPassed()"
                                    class="inline-block px-3 py-1 text-xs font-normal text-tethys-5 bg-tethys-3 rounded-xl shadow-sm ml-2 sm:ml-0"
                                    :title="$t('dataset_under_embargo_icon')"
                                >
                                    <i class="fa-solid fa-hourglass-half"></i>
                                </span>
                            </span>

                            <!-- Section for TITLE -->
                            <span v-if="dataset.hasOwnProperty('titles')" class="flex flex-wrap px-5 pb-4">
                                <span class="w-full font-bold text-black text-xl ">
                                    <span v-if="dataset.MainTitle?.language.toUpperCase() == $i18n.locale">
                                        <p>{{ dataset.MainTitle?.value }}</p>
                                    </span>
                                    <span v-else>
                                        <p v-if="dataset.hasTranslatedTitle()">{{ dataset.TranslatedTitle?.value }}</p>
                                        <p v-else>{{ dataset.MainTitle?.value }}</p>
                                    </span>
                                </span>
                            </span>

                            <!-- Section for dataset's DOI if available -->
                            <span class="inline-block px-5 pb-4">
                                <!-- DOI label -->
                                <span class="px-2 py-1.5 text-xs font-bold text-gray-500 bg-gray-200 rounded shadow-sm" :title="$t('data_type')">
                                    DOI
                                </span>
                                <a v-if="dataset.identifier"
                                    target="_blank"
                                    :href="'https://doi.org/' + dataset.identifier.value"
                                    class="text-tethys-4 text-sm pl-2"
                                >
                                    {{ "https://doi.org/" + dataset.identifier.value }}
                                </a>
                            </span>

                            <!-- Section for AUTHOR -->
                            <div class="text-gray-500 px-5 pb-12">
                                <p v-if="dataset.authors.length > 0">
                                    <span class="flex flex-wrap gap-1 items-center">
                                        <span 
                                            v-for="(author, index) in authorsList" 
                                            :key="index" 
                                            class="flex items-center"
                                        >
                                            <!-- Author Name -->
                                            <span> {{ author.displayName }} </span>
                                            
                                            <!-- SVG Icon (only if ORCID ID exists) -->
                                            <a 
                                                v-if="author.identifier_orcid != null" 
                                                :href="'https://orcid.org/' + author.identifier_orcid" 
                                                target="_blank" 
                                                class="relative group pl-1 pr-0.5"
                                            >
                                                <img 
                                                    src="../../assets/site/img/ORCID-iD_icon_unauth_vector.svg" 
                                                    alt="ORCID Logo" 
                                                    class="w-4 h-4"
                                                />
                                                <span 
                                                    class="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 hidden group-hover:block bg-black text-white text-xs rounded px-2 py-1 whitespace-nowrap"
                                                >
                                                    {{ author.displayName + " - " + $t('visit_orcid') }}
                                                </span>
                                            </a>

                                            <!-- Separator -->
                                            <span v-if="index < authorsList.length - 1" class="pr-1">{{ ";" }}</span>
                                        </span>
                                    </span>
                                </p>
                                <p v-else>-</p>
                            </div>

                            <!-- Section for dataset ABSTRACTS -->
                            <div v-if="dataset.hasOwnProperty('abstracts')" class="flex flex-wrap px-5 pb-10">
                                <div class="w-full">
                                    <span v-if="dataset.MainAbstract?.language.toUpperCase() == $i18n.locale">
                                        <p>{{ dataset.MainAbstract?.value }}</p>
                                    </span>
                                    <span v-else>
                                        <p v-if="dataset.hasTranslatedAbstract()">{{ dataset.TranslatedAbstract?.value }}</p>
                                        <p v-else>{{ dataset.MainAbstract?.value }}</p>
                                    </span>
                                </div>
                            </div>

                            <!-- Section for dataset FILES and their details -->
                            <div class="flex flex-wrap px-5 pb-5">
                                
                                <div v-if="dataset.files.length > 0" class="w-full">

                                    <div class="relative">
                                        <!-- Files Table -->
                                        <table class="hidden md:table w-full mb-4 text-xs">
                                            <thead class="text-left">
                                                <tr class="border-b-2 border-gray-200 bg-gray-100">
                                                    <th class="text-gray-500 px-4 py-3">{{ $t('file') }}</th>
                                                    <!-- TODO: We dont really know the language of the files
                                                    <th class="border px-4 py-2 text-left">{{ $t('language') }}</th> -->
                                                    <th class="text-gray-500 px-4 py-3">{{ $t('format') }}</th>
                                                    <th class="text-gray-500 px-4 py-3">{{ $t('size') }}</th>
                                                    <th class="text-gray-500 px-4 py-3">{{ $t('action') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr 
                                                    v-for="file in dataset.files" 
                                                    :key="file.id" 
                                                    class="hover:bg-gray-50 text-gray-600"
                                                >
                                                    <td class="border-b-2 border-gray-200 px-4 py-3">{{ file.label }}</td>
                                                    <td class="border-b-2 border-gray-200 px-4 py-3">
                                                        <span class="px-2 py-1 text-xs font-bold text-gray-400 bg-gray-200 rounded shadow-sm" :title="$t('data_type')">
                                                            {{ getExtension(file.path_name) }}
                                                        </span>
                                                    </td>
                                                    <td class="border-b-2 border-gray-200 px-4 py-3">{{ formatSize(file.file_size) }}</td>
                                            
                                                    <!-- <td class="border px-4 py-2 items-center space-x-2 flex-wrap justify-center w-full md:w-1/2"> -->
                                                    <td class="border-b-2 border-gray-200 px-4 py-3">
                                                        <!-- Before rendering the download or preview buttons, it is checked if there is an embargo -->
                                                        <span v-if="dataset.hasEmbargoPassed()">
                                                            <!-- Download Button -->
                                                            <a
                                                                class="px-2 py-1.5 border border-tethys-4 shadow-sm bg-tethys-4 hover:bg-tethys-6 hover:border-tethys-6 text-white transition-colors rounded-md cursor-pointer"
                                                                :title="$t('download_file')"
                                                                target="_blank" 
                                                                :href="portal + file.id"
                                                            >
                                                                <i class="fas fa-download text-xs pr-1"></i> Download
                                                            </a>
                                                            <span v-if="isFileSupported(getExtension(file.path_name))" >
                                                                <!-- Spacer between both buttons -->
                                                                <!-- <span class="pr-1"></span> -->
                                                                <!-- Preview Button -->
                                                                <span
                                                                    :class="['ml-1 px-2 py-1.5 border border-gray-300 shadow-sm hover:bg-gray-300 text-gray-500 transition-colors rounded-md cursor-pointer', 
                                                                    { 'text-tethys-4 bg-tethys-3 border-tethys-3': fileLabel === file.label }]"
                                                                    :title="$t('preview_file')"
                                                                    @click="previewFile(file.label, getExtension(file.path_name), portal + file.id)"
                                                                >
                                                                    <i class="fa-solid fa-eye text-xs pr-1"></i> View
                                                                </span>
                                                            </span>
                                                            <span v-else>
                                                                <!-- Spacer between both buttons -->
                                                                <!-- <span class="pr-1"></span> -->
                                                                <!-- Preview Button -->
                                                                <span
                                                                    class="ml-1 px-2 py-1.5 border border-gray-300 shadow-sm text-gray-500 rounded-md cursor-not-allowed"
                                                                    :title="$t('preview_not_supported')"
                                                                >
                                                                    <i class="fa-solid fa-eye-slash text-xs pr-1"></i> View
                                                                </span>
                                                            </span>

                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <!-- Stacked layout for smaller screens -->
                                        <!-- Accordion with 1 item -->
                                        <Accordion class="block md:hidden pb-4" customClass="files-small-screen" :itemCount="1">
                                            <!-- Titles -->
                                            <template #title="{ index }">
                                                <p class="font-sans font-bold uppercase">{{ $t('files') }}</p>
                                            </template>

                                            <!-- Contents -->
                                            <template #content="{ index }">
                                                <div 
                                                    v-for="file in dataset.files" 
                                                    :key="file.id" 
                                                    class="border-b border-b-gray-300 mb-1 p-4"
                                                >
                                                    <div class="truncate mb-1">
                                                        <a 
                                                            target="_blank" 
                                                            :href="portal + file.id" 
                                                            class="text-primary-tethys font-bold hover:underline"
                                                        >
                                                            <i class="fas fa-file-download mr-1"></i>
                                                            {{ file.label }}
                                                        </a>
                                                    </div>
                                                    <!-- <div class="mb-1">
                                                        <strong>{{ $t('language') + ': '}}</strong> {{ getLanguage(dataset.language) }}
                                                    </div> -->
                                                    <div class="mb-1">
                                                        <strong>{{ $t('format') + ': '}}</strong> {{ getExtension(file.path_name) }}
                                                    </div>
                                                    <div>
                                                        <strong>{{ $t('size') + ': '}}</strong> {{ formatSize(file.file_size) }}
                                                    </div>
                                                </div>
                                            </template>
                                        </Accordion>

                                        <!-- Embargo Overlay -->
                                        <div
                                            v-if="!dataset.hasEmbargoPassed()"
                                            class="absolute inset-0 bg-tethys-3 bg-opacity-80 flex items-center justify-center text-tethys-5 text-center text-xs md:text-lg p-4 rounded-lg"
                                        >
                                            <p class="font-semibold">
                                                {{ $t('dataset_under_embargo') + ": " }} <span class="font-bold"> {{ getSimpleDate(dataset.embargo_date) }} </span>   <br />
                                                <span class="hidden md:inline">{{ $t('meanwhile_access_restricted') }}</span>
                                            </p>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <Accordion class="hidden md:block px-5 pb-5" customClass="left-panel" :itemCount="1" ref="previewAccordion">
                                <!-- Titles -->
                                <template #title="{ index }">
                                    <div class="font-bold text-base">{{ $t('preview_file') + ": " }}
                                        <span v-if="fileLabel != ''" class="font-bold text-xs pl-2 text-tethys-4">
                                            {{ fileLabel + "." + fileType }}
                                        </span> 
                                    </div> 
                                </template>

                                <!-- Contents -->
                                <template #content="{ index }">
                                    <div v-if="fileType === 'pdf'">
                                        <!-- PDF Viewer -->
                                        <iframe
                                            :src="fileUrl"
                                            width="100%"
                                            height="500px"
                                            frameborder="0"
                                            class="border border-gray-300"
                                        ></iframe>
                                    </div>

                                    <div v-else-if="fileType === 'csv' || fileType === 'txt'">
                                        <!-- Table Viewer for CSV and TXT -->
                                        <div class="overflow-auto max-h-[500px] sm:max-w-3xl lg:max-w-5xl border border-gray-300">
                                            <table class="table-auto w-full text-left border-collapse">
                                                <thead class="bg-gray-100 sticky top-0 z-10">
                                                    <tr>
                                                        <th
                                                            v-for="(header, idx) in textHeaders"
                                                            :key="'header-' + idx"
                                                            scope="col"
                                                            class="border-b border-gray-300 px-1 py-1 text-xs font-semibold text-gray-700 whitespace-normal"
                                                        >
                                                            {{ header }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(row, idx) in textRows"
                                                        :key="'row-' + idx"
                                                        class="hover:bg-gray-100"
                                                    >
                                                        <td
                                                            v-for="(cell, i) in row"
                                                            :key="'cell-' + idx + '-' + i"
                                                            class="border-b border-gray-200 px-1 py-1 text-xs text-gray-600 whitespace-nowrap"
                                                        >
                                                            {{ cell }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <div v-if="fileType === 'txt'" class="text-gray-600 py-2">
                                                    ... only the first 100 lines are previewed ...
                                                </div>
                                            </table>
                                        </div>
                                        <div v-if="!textHeaders.length" class="text-center text-gray-500 py-4">
                                            No data available to display.
                                        </div>
                                    </div>

                                    <!-- <div v-else-if="fileType === 'txt'"> -->
                                        <!-- TXT Viewer -->
                                        <!-- <pre class="p-4 bg-gray-50 border border-gray-300 rounded whitespace-pre-wrap">{{ txtFile }}</pre>
                                    </div> -->

                                    <div v-else-if="fileType === 'jpg' || fileType === 'png'">
                                        <!-- Image Viewer -->
                                        <img :src="fileUrl" alt="Image Preview" class="max-w-full border border-gray-300" />
                                    </div>

                                    <div v-else>
                                        <!-- Unsupported File Type -->
                                        <p class="text-gray-500">{{ $t('click_in_preview_icon') }}</p>
                                    </div>
                                </template>
                            </Accordion>


                            <!-- Accordion with 1 item for FURTHER DETAILS -->
                            <Accordion class="px-5 pb-5" :itemCount="1" customClass="left-panel">
                                <!-- Titles -->
                                <template #title="{ index }">
                                    <div class="font-bold text-base" v-if="index === 0">{{ $t('further_details') }}</div>
                                    <!-- <div class="uppercase font-semibold" v-else-if="index === 1">{{ $t('technical_metadata') }}</div> -->
                                    <!-- <div v-else>Title for Section 3</div> -->
                                </template>

                                <!-- Contents -->
                                <template #content="{ index }">
                                    <div v-if="index === 0">
                                        <!-- Custom content for Section 1 -->

                                        <!-- Section for METHOD description -->
                                        <div v-if="dataset.hasOwnProperty('abstracts')" class="flex flex-wrap mb-8 mt-3">
                                            <span class="w-full font-bold uppercase mb-4">{{ $t('method') + ':' }}</span>
                                            <span v-if="dataset.hasMethodsAbstract()" class="w-full justify-normal">
                                                <!-- {{ dataset.MethodsAbstract?.value }} -->
                                                <span v-if="dataset.MethodsAbstract?.language.toUpperCase() == $i18n.locale">
                                                    <p>{{ dataset.MethodsAbstract?.value }}</p>
                                                </span>
                                                <span v-else>
                                                    <p v-if="dataset.hasTranslatedMethodsAbstract()">{{ dataset.TranslatedMethodsAbstract?.value }}</p>
                                                    <p v-else>{{ dataset.MethodsAbstract?.value }}</p>
                                                </span>
                                            </span>
                                            <span v-else>-</span>
                                        </div>

                                        <!-- Section for SERIES information -->
                                        <div v-if="dataset.hasOwnProperty('abstracts')" class="flex flex-wrap mb-8">
                                            <span class="w-full font-bold uppercase mb-4">{{ $t('series_information') + ':' }}</span>
                                            <span v-if="dataset.hasSeriesInformationAbstract()" class="w-full">
                                                <span v-if="dataset.SeriesInformationAbstract?.language.toUpperCase() == $i18n.locale">
                                                    <p>{{ dataset.SeriesInformationAbstract?.value }}</p>
                                                </span>
                                                <span v-else>
                                                    <p v-if="dataset.hasTranslatedSeriesInformationAbstract()">{{ dataset.TranslatedSeriesInformationAbstract?.value }}</p>
                                                    <p v-else>{{ dataset.SeriesInformationAbstract?.value }}</p>
                                                </span>
                                            </span>
                                            <span v-else>-</span>
                                        </div>

                                        <!-- Section for PUBLICATION LANGUAGE -->
                                        <div class="flex flex-wrap mb-8">
                                            <span class="w-full font-bold uppercase mb-2">{{ $t('publication_language') + ':' }}</span>
                                            <span v-if="getLanguage(dataset.language) == 'Deutsch'"> {{ $t('german') }}</span>
                                            <span v-else> {{ $t('english') }}</span>
                                        </div>

                                        <!-- Section for EMBARGO -->
                                        <div class="flex flex-wrap mb-8">
                                            <span class="w-full font-bold uppercase mb-2">{{ $t('embargo') + ':' }}</span>
                                            <span v-if="dataset.embargo_date">{{ getHumanDate(dataset.embargo_date) }}</span>
                                            <span v-else>-</span>
                                        </div>

                                        <!-- Section for CONTRIBUTORS -->
                                        <div class="flex flex-wrap mb-1">
                                            <span class="w-full font-bold uppercase mb-2">{{ $t('contributor') + ':' }}</span>
                                            <span v-if="dataset.hasContributors()">{{ dataset.contributors.map((u) => u.full_name).join(", ") }}</span>
                                            <span v-else>-</span>
                                        </div>

                                    </div>

                                </template>
                            </Accordion>

                           <!-- Accordion with 1 item for CITATION -->
                           <Accordion class="px-5 pb-6" :itemCount="1" customClass="left-panel">
                                <!-- Titles -->
                                <template #title="{ index }">
                                    <div class="font-bold text-base" v-if="index === 0">{{ $t('citation') }}</div>
                                </template>

                                <!-- Contents -->
                                <template #content="{ index }">
                                    <div v-if="index === 0">
                                        <!-- Section showing citation -->
                                        <div class="relative block">
                                            <p class="pb-6">
                                                {{ getCitation() }}
                                                <!-- Link to the dataset's DOI if available -->
                                                <a
                                                    v-if="dataset.identifier"
                                                    target="_blank"
                                                    class="text-secondary-tethys hover:underline"
                                                    :href="'https://doi.org/' + dataset.identifier.value"
                                                >
                                                    ({{ "https://doi.org/" + dataset.identifier.value }})
                                                </a>
                                            </p>
                                            <!-- Copy button -->
                                            <span
                                                @click="copyToClipboard(dataset.identifier.value.toString())"
                                                class="absolute bottom-0 right-0 cursor-pointer px-2 py-1 text-xs font-normal rounded-md border border-gray-300 shadow-sm hover:bg-gray-200"
                                                :title="$t('copy_citation_tooltip')"
                                            >
                                                <i class="fa-regular fa-clone pr-1"></i> {{ $t('copy_citation') }}
                                            </span>
                                        </div>
                                    </div>

                                </template>
                            </Accordion>

                        </div>

                    </div>

                    <!-- RIGHT content block displaying additional dataset details -------------------------------------------------------------------------------- --> 
                    <div class="w-full lg:w-4/12 pl-10">
                        
                        <!-- First line headline DETAILS -->
                        <div class="flex justify-end items-center">
                            <span
                                class="px-2 py-2 text-sm text-gray-400 uppercase"
                            >
                                {{ $t('details') }}
                            </span>
                        </div>
                        
                        <!-- Container for minimap and coverage details -->
                        <div class="bg-white border shadow-xl rounded-xl p-5 mb-5">
                            <div>
                                <Minimap :bounds="dataset.Bounds" class="relative z-10 rounded-lg" ></Minimap>
                            </div>

                            <div class="pb-5">
                                <!-- Section for Abdeckung/Coverage -->
                                <h3 class="text-gray-500 text-xs font-bold py-2">{{ $t('coverage') }}:</h3>
                                <!-- Accordion with several items. Can display up to 4 attributes from Coverage. Good testing Tethys dataset: 5 -->                                                    
                                <Accordion customClass="coverage" :itemCount="coverageAttributes.length">
                                    <!-- Titles -->
                                    <template #title="{ index }">
                                        {{ $t(coverageAttributes[index].key) }}
                                    </template>
                                    <!-- Contents -->
                                    <template #content="{ index }">
                                        <div v-if="coverageAttributes[index].values">
                                            <template v-for="(value, label) in coverageAttributes[index].values" :key="label">
                                                <!-- <p v-if="value">{{ $t(label) + ": " + value }}</p> -->
                                                <span v-if="value">
                                                    <p v-if="coverageAttributes[index].key == 'geolocation'">
                                                        {{ $t(label) + ": " + value.toFixed(6) }}
                                                    </p>
                                                    <p v-else>
                                                        <span v-if="coverageAttributes[index].key == 'elevation' || coverageAttributes[index].key == 'depth'">
                                                            {{ $t(label) + ": " + Intl.NumberFormat('de-de').format(value) + " m." }}
                                                        </span>
                                                        <span v-else>
                                                            {{ $t(label) + ": " + value }}
                                                        </span>
                                                    </p>
                                                </span>
                                            </template>
                                        </div>
                                        <p v-else>No data available</p>
                                    </template>
                                </Accordion>
                            </div>

                            <!-- Section for the CREATORs -->
                            <div class="flex text-gray-500 text-sm pb-5">
                                <p class="font-semibold pb-3 pr-5">{{ $t('creator') + ':' }} </p>
                                <p v-if="dataset.authors.length > 0">
                                    <span v-if="accessNotFromDoi()" class="flex flex-wrap">
                                        <span 
                                            v-for="(author, index) in authorsList" 
                                            :key="index" 
                                            class="pb-1 pr-1"
                                            :title="$t('search_creator')"
                                        >
                                            <router-link
                                                :to="{ name: 'Search', params: { display: author.lastName, type: 'authors' } }"
                                                class="text-sm text-gray-400 hover:bg-gray-200 transition-colors"
                                            >
                                                {{ author.displayName }}
                                            </router-link>
                                            <span v-if="index < authorsList.length - 1" class="text-sm text-gray-400">;</span>
                                        </span>
                                    </span>
                                    <span v-else class="flex flex-wrap gap-1">
                                        <span 
                                            v-for="(author, index) in authorsList" 
                                            :key="index" 
                                            class="pb-1 pr-1"
                                            :title="$t('search_creator')"
                                        >
                                            <a
                                                target="_blank"
                                                :href= "search_url + '/' + author.lastName + '/authors'" 
                                                class="text-sm text-gray-400 hover:bg-gray-200 transition-colors"
                                            >
                                                {{ author.displayName }}
                                            </a>
                                            <span v-if="index < authorsList.length - 1" class="text-sm text-gray-400">;</span>
                                        </span>
                                    </span>
                                </p>
                                <p v-else>-</p>
                            </div>  

                            <!-- Section for the LICENSE information -->
                            <div class="flex text-gray-500 text-sm pb-5">
                                <p class="font-semibold pb-3 pr-5">{{ $t('license') + ':' }} </p>                                
                                <p v-if="dataset.hasLicenses()">
                                    <label v-for="license in dataset.licenses" :key="license.id">
                                        <!-- Link to the appropriate Creative Commons license -->
                                        <span class="font-normal px-2">
                                            <a v-if="license.name=='CC-BY-4.0'" target="_blank" class=" text-gray-400" :href="'https://creativecommons.org/licenses/by/4.0/'">
                                                <i class="fa-brands fa-creative-commons"></i><span class="text-tethys-4 hover:underline pl-1">{{ license.name }}</span>
                                            </a>
                                            <a v-else target="_blank" class="text-gray-400" :href="'https://creativecommons.org/licenses/by-sa/4.0/'">
                                                <i class="fa-brands fa-creative-commons"></i><span class="text-tethys-4 hover:underline pl-1">{{ license.name }}</span>
                                            </a>
                                        </span>
                                    </label>
                                </p>
                            </div>

                            <!-- Section for the KEYWORDS list -->
                            <div class="text-gray-500 pb-5">
                                <p class="font-semibold pb-2 mb-3 border-b-2 border-gray-200">{{ $t('keywords') + ':' }}</p>
                                <p v-if="dataset.hasOwnProperty('subjects')">
                                    <!-- Iterate through subjects and display them as router links -->
                                    <!-- If the access to landing page has not been done from a DOI, the internal router is used -->
                                    <span v-if="accessNotFromDoi()" class="flex flex-wrap gap-1">
                                        <span v-for="(subject, index) in dataset.subjects" :key="subject.value" class="pb-2 flex-shrink-0">
                                            <router-link
                                                :to="{ name: 'Search', params: { display: subject.value, type: 'subjects' } }"
                                                class="px-2 py-1 text-xs text-tethys-5 bg-tethys-2 rounded-xl border border-tethys-2 shadow-sm hover:bg-tethys-6 hover:text-white hover:border-tethys-6 transition-colors"
                                                :title="$t('search_keyword')"
                                            >
                                                {{ subject.value }}
                                            </router-link>
                                        </span>
                                    </span>
                                    <!-- Otherwise, a normal link is added to the subject -->
                                    <span v-else class="flex flex-wrap gap-1">
                                        <!-- {{ dataset.subjects.map((u) => u.value).join(", ") }} -->
                                        <span v-for="(subject, index) in dataset.subjects" :key="subject.value" class="pb-2 flex-shrink-0">
                                            <a
                                                target="_blank"
                                                :href= "search_url + '/' + subject.value + '/subjects'" 
                                                class="px-2 py-1 text-xs text-tethys-5 bg-tethys-2 rounded-xl border border-tethys-2 shadow-sm hover:bg-tethys-6 hover:text-white hover:border-tethys-6 transition-colors"
                                                :title="$t('search_keyword')"
                                            >
                                                {{ subject.value }}
                                            </a>
                                        </span>
                                    </span>
                                </p>
                                <p v-else>-</p>
                            </div>

                            <!-- Section for the PROJECT information -->
                            <div class="text-gray-500 pb-5">
                                <p class="font-semibold pb-2 mb-3 border-b-2 border-gray-200">{{ $t('project') + ':' }}</p>

                                <p v-if="dataset.project != null">
                                    <span>{{ dataset.project.label }}</span> 
                                    <i class="fas fa-circle-info  text-tethys-6 hover:text-black pl-1" @click="showModal" :title="$t('project_details')"></i>

                                    <!-- Modal -->
                                    <div
                                        v-if="isShowModal"
                                        @click.self="closeModal"
                                        class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                                        aria-hidden="true"
                                    >
                                        <div class="relative w-full max-w-xl p-5 bg-white rounded-lg shadow">
                                            <!-- Modal Header -->
                                            <div class="flex items-start justify-between p-4 mb-4 border-b rounded-t">
                                                <span class="text-sm font-bold text-gray-700 bg-white rounded-md border border-gray-300 shadow-sm px-2 py-1">
                                                    {{ dataset.project?.label }}
                                                </span>
                                                <!-- <p class="font-bold pr-5">{{ dataset.project?.label }}</p> -->
                                                <p class="font-semibold italic text-left pl-3 py-1">{{ dataset.project?.name }}</p>
                                                <button
                                                    @click="closeModal"
                                                    type="button"
                                                    class="text-gray-400 hover:text-gray-900 py-1"
                                                >
                                                    <svg
                                                        class="w-5 h-5"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </button>
                                            </div>

                                            <!-- Modal Body -->
                                            <div class="p-4 text-justify">
                                                <p>
                                                    {{ dataset.project.description }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </p>
                                <p v-else>-</p>

                            </div>

                             <!-- Section for the PUBLISHER -->
                            <div class="text-gray-500 pb-5">
                                <p class="font-semibold pb-2 mb-3 border-b-2 border-gray-200">{{ $t('publisher') + ':' }}</p>
                                <p>{{ dataset.publisher_name }}</p>
                            </div>

                        </div>


                        <!-- Container for REFERENCES -->
                        <div class="bg-white border shadow-xl rounded-xl p-5 mb-5">

                            <!-- Accordion with 1 item -->
                            <Accordion class="" customClass="right-panel" :itemCount="1" ref="referencesAccordion">
                                <!-- Titles -->
                                <template #title="{ index }">
                                    <div class="font-semibold">{{ $t('references') }}</div>
                                </template>

                                <!-- Contents -->
                                <template #content="{ index }">
                                    <!-- Section for references listed -->
                                    <div class="border-t-2 border-gray-200 pt-4">
                                        <ul v-if="dataset.references.length> 0">
                                            <li v-for="(reference, i) in dataset.references" :key="reference.id" class="pb-2">
                                                <p><i class="fa-solid fa-right-to-bracket text-tethys-6 pb-1"></i><span class="pl-2 text-sm">{{ $t(reference.relation) + ":" }}</span></p>
                                                <!-- Link to reference if it's a DOI or URL -->
                                                <span v-if="reference.type == 'DOI' || reference.type == 'URL'" >
                                                    <a target="_blank" class="block text-sm italic text-tethys-4 hover:underline pb-2 pl-5" :href="reference.value">
                                                        {{ reference.label }}
                                                    </a>
                                                </span>
                                                <span v-else class="text-gray-700">
                                                    <!-- ISBNs 978-3-85316 and 978-3-900312 are those corresponding to the GBA library! -->
                                                    <span v-if="reference.value.indexOf('85316') != -1 || reference.value.indexOf('900312') != -1"> 
                                                        <a target="_blank" class="block text-sm italic text-tethys-4 hover:underline pb-2 pl-4" :href="'https://bibliothek.geosphere.at/cgi-bin/koha/opac-search.pl?idx=&q=' + reference.value">
                                                            {{ `(${reference.type}): ${reference.value}` }}
                                                        </a>
                                                    </span>
                                                    <span v-else class="block text-sm italic pb-2 pl-4">
                                                        {{ `(${reference.type}): ${reference.value}` }}
                                                    </span>
                                                </span>
                                            </li>
                                        </ul>
                                        <p v-else>{{ $t('no_linked_references') }}</p>
                                    </div>
                                </template>
                            </Accordion>
                        </div>

                        <!-- Container for TECHNICAL METADATA -->
                        <div class="bg-white border shadow-xl rounded-xl p-5 mb-8">
                            <!-- Accordion with 1 item -->
                            <Accordion class="" customClass="right-panel" :itemCount="1">
                                <!-- Titles -->
                                <template #title="{ index }">
                                    <div class="font-semibold">{{ $t('technical_metadata') }}</div>
                                </template>

                                <!-- Contents -->
                                <template #content="{ index }">
                                    <div class="border-t-2 border-gray-200 pt-4">
                                        <!-- Custom content for Section 2 -->
                                        <p>
                                            <span class="font-semibold">{{ $t('persistent_identifier') + ': '}}</span>
                                            {{ dataset.url }}
                                        </p>
                                        <p>
                                            <span class="font-semibold">{{ $t('status') + ': '}}</span>
                                            {{ dataset.server_state }}
                                        </p>
                                        <p v-if="dataset.hasOwnProperty('user')">
                                            <span class="font-semibold">{{ $t('posted_by') + ': '}}</span>
                                            {{ dataset.user.login }}
                                        </p>
                                        <p>
                                            <span class="font-semibold">{{ $t('editor') + ': '}}</span>
                                            {{ dataset.creating_corporation }}
                                        </p>
                                    </div>
                                </template>
                            </Accordion>

                        </div>
                        
                    </div>
                </div>
            </div>

        </section>

    </div>

</template>

<script lang="ts">
import Minimap from "@/components/Minimap.vue";
import Accordion from "@/components/Accordion/Accordion.vue";
import DatasetDetailComponent from "./dataset-detail.component";
export default DatasetDetailComponent;
</script>

<style scoped lang="scss">

.search-banner {
    background-image: url('/src/assets/site/img/main-search-banner.jpg');
}
</style>
