import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "flex flex-col items-center w-full h-full bg-tethys-1 overflow-hidden",
  autocomplete: "off"
}
const _hoisted_2 = { class: "search-banner w-full bg-cover bg-no-repeat min-h-64 lg:min-h-[350px] bg-[position:50%_50%] relative before:absolute before:top-0 before:left-0 before:w-full before:h-4 before:bg-gradient-to-b before:from-black before:to-transparent before:opacity-20 after:absolute after:bottom-0 after:left-0 after:w-full after:h-4 after:bg-gradient-to-t after:from-black after:to-transparent after:opacity-20" }
const _hoisted_3 = { class: "w-full px-4 pt-16 pb-32 bg-white text-center" }
const _hoisted_4 = { class: "w-10/12 xl:w-5/6 2xl:w-4/6 mx-auto" }
const _hoisted_5 = { class: "text-2xl md:text-3xl font-bold text-tethys-5 mb-4" }
const _hoisted_6 = { class: "text-gray-500 text-sm md:text-base max-w-4xl mx-auto mb-10 leading-relaxed" }
const _hoisted_7 = { class: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6" }
const _hoisted_8 = { class: "group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg" }
const _hoisted_9 = { class: "font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1" }
const _hoisted_10 = { class: "text-sm text-gray-500 transition-all duration-300" }
const _hoisted_11 = { class: "inline group-hover:hidden" }
const _hoisted_12 = { class: "hidden group-hover:inline" }
const _hoisted_13 = { class: "group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg" }
const _hoisted_14 = { class: "font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1" }
const _hoisted_15 = { class: "text-sm text-gray-500 transition-all duration-300" }
const _hoisted_16 = { class: "inline group-hover:hidden" }
const _hoisted_17 = { class: "hidden group-hover:inline" }
const _hoisted_18 = { class: "group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg" }
const _hoisted_19 = { class: "font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1" }
const _hoisted_20 = { class: "text-sm text-gray-500 transition-all duration-300" }
const _hoisted_21 = { class: "inline group-hover:hidden" }
const _hoisted_22 = { class: "hidden group-hover:inline" }
const _hoisted_23 = { class: "group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg" }
const _hoisted_24 = { class: "font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1" }
const _hoisted_25 = { class: "text-sm text-gray-500 transition-all duration-300" }
const _hoisted_26 = { class: "inline group-hover:hidden" }
const _hoisted_27 = { class: "hidden group-hover:inline" }
const _hoisted_28 = { class: "group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg" }
const _hoisted_29 = { class: "font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1" }
const _hoisted_30 = { class: "text-sm text-gray-500 transition-all duration-300" }
const _hoisted_31 = { class: "inline group-hover:hidden" }
const _hoisted_32 = { class: "hidden group-hover:inline" }
const _hoisted_33 = { class: "group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg" }
const _hoisted_34 = { class: "font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1" }
const _hoisted_35 = { class: "text-sm text-gray-500 transition-all duration-300" }
const _hoisted_36 = { class: "inline group-hover:hidden" }
const _hoisted_37 = { class: "hidden group-hover:inline" }
const _hoisted_38 = { class: "group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg" }
const _hoisted_39 = { class: "font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1" }
const _hoisted_40 = { class: "text-sm text-gray-500 transition-all duration-300" }
const _hoisted_41 = { class: "inline group-hover:hidden" }
const _hoisted_42 = { class: "hidden group-hover:inline" }
const _hoisted_43 = { class: "group h-[350px] bg-gray-50 rounded-xl shadow-sm py-6 px-5 transition-transform duration-300 hover:-translate-y-1 hover:shadow-lg" }
const _hoisted_44 = { class: "font-semibold text-gray-700 group-hover:text-tethys-5 group-hover:text-lg mb-1" }
const _hoisted_45 = { class: "text-sm text-gray-500 transition-all duration-300" }
const _hoisted_46 = { class: "inline group-hover:hidden" }
const _hoisted_47 = { class: "hidden group-hover:inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vs_input = _resolveComponent("vs-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vs_input, {
        placeholder: _ctx.$t('enter_your_search_term'),
        onSearchChange: _ctx.onSearch
      }, null, 8, ["placeholder", "onSearchChange"])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('home_title')), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('home_subtitle')), 1),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl" }, [
              _createElementVNode("i", { class: "fas fa-shield-alt" })
            ], -1)),
            _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t('cards.safe.title')), 1),
            _createElementVNode("p", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('cards.safe.description_default')), 1),
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('cards.safe.description_hover')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl" }, [
              _createElementVNode("i", { class: "fas fa-file-alt" })
            ], -1)),
            _createElementVNode("h3", _hoisted_14, _toDisplayString(_ctx.$t('cards.comprehensible.title')), 1),
            _createElementVNode("p", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('cards.comprehensible.description_default')), 1),
              _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('cards.comprehensible.description_hover')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl" }, [
              _createElementVNode("i", { class: "fas fa-code-branch" })
            ], -1)),
            _createElementVNode("h3", _hoisted_19, _toDisplayString(_ctx.$t('cards.versioning.title')), 1),
            _createElementVNode("p", _hoisted_20, [
              _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t('cards.versioning.description_default')), 1),
              _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('cards.versioning.description_hover')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl" }, [
              _createElementVNode("i", { class: "fas fa-database" })
            ], -1)),
            _createElementVNode("h3", _hoisted_24, _toDisplayString(_ctx.$t('cards.fair_principles.title')), 1),
            _createElementVNode("p", _hoisted_25, [
              _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t('cards.fair_principles.description_default')), 1),
              _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t('cards.fair_principles.description_hover')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl" }, [
              _createElementVNode("i", { class: "fas fa-check-circle" })
            ], -1)),
            _createElementVNode("h3", _hoisted_29, _toDisplayString(_ctx.$t('cards.certified.title')), 1),
            _createElementVNode("p", _hoisted_30, [
              _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t('cards.certified.description_default')), 1),
              _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t('cards.certified.description_hover')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_33, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl" }, [
              _createElementVNode("i", { class: "fas fa-quote-right" })
            ], -1)),
            _createElementVNode("h3", _hoisted_34, _toDisplayString(_ctx.$t('cards.citable.title')), 1),
            _createElementVNode("p", _hoisted_35, [
              _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.$t('cards.citable.description_default')), 1),
              _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.$t('cards.citable.description_hover')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_38, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl" }, [
              _createElementVNode("i", { class: "fas fa-unlock" })
            ], -1)),
            _createElementVNode("h3", _hoisted_39, _toDisplayString(_ctx.$t('cards.open_access.title')), 1),
            _createElementVNode("p", _hoisted_40, [
              _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.$t('cards.open_access.description_default')), 1),
              _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.$t('cards.open_access.description_hover')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_43, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "w-16 h-16 mx-auto mb-3 flex items-center justify-center bg-tethys-3 group-hover:bg-blue-200 rounded-full text-tethys-5 text-2xl group-hover:text-3xl" }, [
              _createElementVNode("i", { class: "fas fa-clipboard-check" })
            ], -1)),
            _createElementVNode("h3", _hoisted_44, _toDisplayString(_ctx.$t('cards.review_process.title')), 1),
            _createElementVNode("p", _hoisted_45, [
              _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.$t('cards.review_process.description_default')), 1),
              _createElementVNode("span", _hoisted_47, _toDisplayString(_ctx.$t('cards.review_process.description_hover')), 1)
            ])
          ])
        ])
      ])
    ])
  ]))
}