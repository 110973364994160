<template>
    <div class="w-full h-full text-xs 3xl:text-sm lg:pt-3 3xl:pt-5 text-tethys-5">
        <!-- Grid containing filter buttons and facets -->
        <div class="w-full h-full flex gap-2">

            <div class="w-full h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-2">
        
                <!-- allFilters Button -->
                <div class="relative w-full">
                    <button 
                        @click="toggleDropdown('allFilters')"

                        :disabled="selectedTags.length < 1"
                        :class="[
                            'w-full min-w-[8rem] flex items-center bg-white border border-gray-300 px-3 py-2 rounded-md font-semibold shadow-sm hover:bg-gray-100 transition allFilters-dropdown', 
                            { 'text-gray-400 hover:bg-white': selectedTags.length < 1 }]"
                    >
                        <i class="fa-solid fa-sliders pr-2"></i> {{ $t("all_filters") }}  
                        <!-- <i class="fa-solid fa-filter"></i> {{ $t("all_filters") }}  -->
                        <span v-if="selectedTags.length > 0">
                            <span class="italic underline pl-3" @click="resetFilters()" :title="$t('reset_tooltip')">Reset</span>
                            <span class="absolute top-0 right-2 transform translate-x-2 -translate-y-2 bg-primary-tethys text-white text-xs font-bold w-4 h-4 flex items-center justify-center rounded-full">
                                {{ selectedTags.length }}
                            </span>
                        </span>
                    </button>
                    <transition name="fade">
                        <div v-if="openDropdown === 'allFilters'" class="absolute top-full left-0 mt-1 bg-white border border-gray-300 shadow-lg rounded-md w-56 z-50 allFilters-dropdown">
                            <div class="p-2 flex flex-wrap items-center gap-1 border-b border-gray-300">
                                <span v-if="selectedTags.length > 0"
                                    v-for="(selected, i) in selectedTags"
                                    :key="i"
                                    class="bg-gray-200 px-2 py-1 rounded flex items-center gap-1 cursor-pointer"
                                >
                                    {{ selected.val }}
                                    <button @click.stop="removeSelection(selected.category, selected)" class="bg-gray-50 text-gray-500 hover:text-gray-700 w-3 h-3 pb-0.5 flex items-center justify-center rounded-full">
                                        &times;
                                    </button>
                                </span>
                                <!-- <span v-else class="text-gray-500 pl-1">
                                    {{ $t("no_filters_selected") }} 
                                </span> -->
                            </div>
                        </div>
                    </transition>
                </div>

                <div v-for="facetKey in fixedFacets" :key="facetKey" class="relative w-full facet-dropdown">
                    <button
                        @click="toggleDropdown(facetKey)"
                        :class="[
                            'w-full min-w-[8rem] flex items-center justify-between relative bg-white border border-gray-300 pl-3 pr-2 py-2 rounded-md font-semibold shadow-sm hover:bg-gray-100 transition', 
                        { '!bg-tethys-3 border-blue-200': facets[facetKey] && facets[facetKey].some(item => item.active) }]"
                        :disabled="!facets[facetKey]"
                    >

                    <!-- #95e1ff -->
                        <span class="uppercase pr-2">{{ $t("facet_" + facetKey) }}</span>

                        <div class="flex items-center gap-1">
                            <span v-if="facets[facetKey] && facets[facetKey].some(item => item.active)"
                                class="bg-primary-tethys text-white text-xs font-bold w-4 h-4 flex items-center justify-center rounded-full">
                                {{ facets[facetKey].filter(item => item.active).length }}
                            </span>
                            <button v-if="facets[facetKey] && facets[facetKey].some(item => item.active)"
                                @click.stop="clearFacet(facetKey)"
                                class="text-gray-500 hover:text-gray-700 focus:outline-none"
                            >
                                <i class="fas fa-times text-primary-tethys"></i>
                            </button>
                            <i v-else class="fas fa-chevron-down text-gray-300"></i>
                        </div>
                    </button>

                    <transition name="fade">
                        <div v-if="openDropdown === facetKey" class="absolute top-full left-0 mt-1 bg-white border border-gray-300 shadow-lg rounded-md w-56 z-50 facet-dropdown">
                            
                            <!-- Search Bar with Selected Tags -->
                            <div class="p-2 flex flex-wrap items-center gap-1 border-b border-gray-300">
                                <!-- Selected tags -->
                                <span 
                                    v-for="(selected, i) in facets[facetKey]?.filter(item => item.active)" 
                                    :key="i"
                                    class="bg-gray-200 text-xs pl-2 pr-1 py-1 rounded flex items-center gap-1.5 cursor-pointer"
                                >
                                    {{ selected.val }} 
                                    <button @click.stop="removeSelection(facetKey, selected)" class="bg-gray-50 text-gray-500 hover:text-gray-700 w-3 h-3 pb-0.5 flex items-center justify-center rounded-full">
                                        &times;
                                    </button>
                                </span>

                                <!-- Search Box with Clear "X" Button -->
                                <div class="relative w-full">
                                    <input
                                        v-model="searchQueries[facetKey]"
                                        type="text"
                                        :placeholder="$t('search')"
                                        class="w-full mt-1 text-xs px-2 py-1 border border-gray-200 outline-none"
                                        :disabled="!facets[facetKey]"
                                    />
                                    <button v-if="searchQueries[facetKey]"
                                        @click="clearSearch(facetKey)"
                                        class="absolute right-2 top-1/2 pt-1 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                                    >
                                        <i class="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                            
                            <!-- List of Options with Checkboxes -->
                            <ul class="p-2 max-h-40 overflow-y-auto">
                                <li v-for="(option, i) in filteredOptions(facetKey)" :key="i"
                                    class="px-1 py-1 flex items-center gap-2 cursor-pointer hover:bg-gray-100"
                                    @click="toggleSelection(facetKey, option)"
                                >
                                    <input type="checkbox" :checked="option.active" class="cursor-pointer" />
                                    <span v-if="facetKey === 'doctype' || facetKey === 'language'">{{ $t(option.val) + " (" + option.count + ")" }}</span>
                                    <span v-else>{{ option.val + " (" + option.count + ")" }}</span>
                                </li>
                            </ul>
                        </div>
                    </transition>
                </div>

            </div>

            <!-- SORTING Button, not affected by the grid containing the other buttons. It will always keep at the right -->
            <div class="shrink-0 w-fit">
                <button @click="toggleDropdown('sort')" class="min-w-28 flex items-center gap-1.5 bg-white border border-gray-300 px-3 py-2 font-semibold shadow-sm hover:bg-gray-100 transition sort-dropdown"
                    :title="$t('sort_by_tooltip')"
                >
                    <span v-if="sortByAttribute === '_score'">
                        <span class="pl-0.5"><i class="fa-solid fa-signal -rotate-90"></i></span>
                    </span>
                    <span v-else>
                        <span v-if="sortByOrder === 'desc'">
                            <i class="fa-solid fa-down-long"></i>
                        </span>
                        <span v-else>
                            <i class="fa-solid fa-up-long"></i>
                        </span>
                        <i class="fa-solid fa-hourglass-half"></i>
                    </span>
                    {{ $t('sort_by') }} 
                </button>
                <transition name="fade">
                    <div v-if="openDropdown === 'sort'" class="absolute mt-1 bg-white border border-gray-300 shadow-lg rounded-md w-32 z-50 sort-dropdown">
                        <!-- Add your sort options here -->
                        <ul class="p-2">
                            <li v-if="hasSearchTerm" class="px-1 py-1 cursor-pointer hover:bg-gray-100" @click="changeOrdering('relevance', '')"
                                :title="$t('relevance_tooltip')"
                            >
                                <!-- <i class="fa-solid fa-arrow-down-wide-short"></i>   -->
                                <span class="pr-2"><i class="fa-solid fa-signal -rotate-90"></i></span>{{ $t('relevance') }}
                            </li>
                            <li class="px-1 py-1 cursor-pointer hover:bg-gray-100" @click="changeOrdering('date', 'descending')"
                                :title="$t('sort_date_desc_tooltip')"
                            >
                                <span class="pr-1"><i class="fa-solid fa-down-long"></i><i class="fa-solid fa-hourglass-half"></i></span> {{ $t('facet_year') }}
                                <!-- <span class="pr-1"><i class="fa-solid fa-arrow-down"></i><i class="fa-solid fa-hourglass-half"></i></span> Pub. year -->
                            </li>
                            <li class="px-1 py-1 cursor-pointer hover:bg-gray-100" @click="changeOrdering('date', 'ascending')"
                                :title="$t('sort_date_asc_tooltip')"
                            >
                                <span class="pr-1"><i class="fa-solid fa-up-long"></i><i class="fa-solid fa-hourglass-half"></i></span> {{ $t('facet_year') }}
                                <!-- <span class="pr-1"><i class="fa-solid fa-arrow-up"></i><i class="fa-solid fa-hourglass-half"></i></span> Pub. year -->
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import FacetsComponent from "./FacetsComponent";
export default FacetsComponent;
</script>

<style>
/* Add smooth fade animation */
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s ease-in-out;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
